import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import AOS from "aos";
import { Link } from "react-router-dom";
import "aos/dist/aos.css";
import HomepageSlider from "./HomepageSlider";
import Warranty from "./Warranty";
import TestimonialsSlider from "./TestimonialsSlider";
import Section1 from "./images/Section-1-img.png";

import blogthumb1 from "./images/blogthumb1.png";
import blogthumb2 from "./images/blogthumb2.png";
import blogthumb3 from "./images/blogthumb3.png";

import OnloadPopup from "./OnloadPopup";

AOS.init({
  disable: isMobile,
});

const photogallery = [
  require("./images/pg-h-8.png"),
  require("./images/pg-h-5.png"),
  require("./images/pg-h-7.png"),
  require("./images/pg-h-4.png"),
  require("./images/pg-h-2.png"),
  require("./images/pg-h-1.png"),
  require("./images/pg-h-6.png"),
  require("./images/pg-h-3.png"),
];

const instagallery = [
  require("./images/insta-h-1.png"),
  require("./images/insta-h-2.png"),
  require("./images/insta-h-3.png"),
  require("./images/insta-h-4.png"),
  require("./images/insta-h-5.png"),
];

const blogs = [
  {
    id: 1,
    title: "Modern Cabinets",
    subtitle: "Lorem Ipsum",
    image: blogthumb1,
    description:
      "Discover the elegance and functionality of modern cabinets for every space.",
  },
  {
    id: 2,
    title: "Transform Your Space Ipsum",
    subtitle: "Style Meets Simplicity",
    image: blogthumb2,
    description:
      "Explore how minimalist kitchen designs can transform your home.",
  },
  {
    id: 3,
    title: "Elevating Your Kitchen",
    subtitle: "Efficient Spaces",
    image: blogthumb3,
    description: "Learn about innovative storage solutions for modern living.",
  },
];
function Home() {
  {
    /*Popup scripts start */
  }
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Check if the popup has been shown in this session
    const popupShown = sessionStorage.getItem("popupShown");

    if (!popupShown) {
      // Set a timer to show the popup after 30 seconds
      const timer = setTimeout(() => {
        setShowPopup(true);
        // Mark the popup as shown in sessionStorage
        sessionStorage.setItem("popupShown", "true");
      }, 30000); // Change to 30000 for 30 seconds

      // Clear the timer on cleanup
      return () => clearTimeout(timer);
    }
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };
  {
    /*Popup scripts End */
  }

  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: false, // Only animate once
      disable: () => window.innerWidth < 768, // Disable on mobile (screen width < 768px)
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="homepage">
      {showPopup && <OnloadPopup closePopup={closePopup} />}
      <div>
        <HomepageSlider />
        <div className="home-section-1-bg">
          <div className="wrapper">
            <div className="home-section-1-center" data-aos="zoom-in-up">
              <h1> Happy Customers</h1>
              <TestimonialsSlider />
            </div>

            {/* <div className="home-section-1-center" data-aos="zoom-in-up">
              <h1>Honestly Amazing.</h1>
              <p>
                Every modular furniture store is offering every kind of
                customization today. Tall promises are made of quotation to
                delivery in 8 weeks. But very seldom is that true, leave aside
                that the quality is not up to the mark, Half the stores do not
                even have their own manufacturing. This is what I thought was
                different about Rostre. There was complete honesty and
                transparency in the design and quotation process. I was invited
                to the factory to see the process and choose my options. The
                installation process was seamless and the quality is
                exceptional. I was honestly amazed!
              </p>
              <p>
                <i>Rahul Singhvi - Happy client in Hyderabad</i>
              </p>
            </div> */}
            <div className="home-section-1-wrap">
              <div className="home-section-1-1" data-aos="fade-up-right">
                <img src={Section1} alt="Section1" />
              </div>
              <div className="home-section-1-2" data-aos="fade-up-left">
                <h2>
                  Why Choose Rostre - <br />
                  Our Commitment to Excellence
                </h2>
                <h4>Honesty and Transparency</h4>
                <p>
                  We believe in building trust with our customers. Our
                  commitment is to promise only what we can deliver, ensuring
                  transparency in every interaction.
                </p>
                <h4>In-House Manufacturing</h4>
                <p>
                  Unlike many retailers, we take pride in our own manufacturing
                  facility. This allows us to maintain strict quality control
                  and ensure that every piece of furniture meets our high
                  standards.
                </p>

                <h4>Space-Saving Solutions</h4>
                <p>
                  Our innovative space-saving modules are designed to maximize
                  every inch of your living and working area, allowing you to
                  fit more functionality and style into the confines of your
                  space.
                </p>

                <Link
                  to="/WhyChooseRostre"
                  className="button-3"
                  onClick={scrollToTop}
                >
                  Read More
                </Link>
                <Link
                  to="/Contactus"
                  className="button-3 black-bg"
                  onClick={scrollToTop}
                >
                  Get a Quote
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="warenty-sec">
          <div className="wrapper">
            <Warranty />
          </div>
        </div>
        <div className="home-section-2-bg">
          <div className="wrapper">
            <h2 className="line-1">Project Gallery</h2>
            <div className="home-gal">
              <ul>
                {photogallery.map((src, i) => (
                  <li key={i}>
                    <img src={src} alt="" data-aos="zoom-in" />
                  </li>
                ))}
              </ul>
            </div>
            <div className="text-center">
              <Link
                to="/Contactus"
                className="button-3 black-bg"
                onClick={scrollToTop}
              >
                Get Free Design
              </Link>
            </div>
          </div>
        </div>

        {/* <div className="home-section-3-bg">
          <h2 className="line-small">Blog</h2>
          <div className="wrapper">
            <div className="blog-sec">
              
            
              <div className="blog-item">
                <Link to="/">
                  <img src={blogthumb1} alt="" />
                  <div className="blog-content">
                    <h4>Modern Cabinets </h4>
                    <h5>Lorem Ipsum</h5>
                  </div>
                </Link>
              </div>
            </div> 
            <div className="blog-sec">
              {blogs.map((blog) => (
                <div key={blog.id} className="blog-item">
                  <Link to={`/blog/${blog.id}`} onClick={scrollToTop}>
                    <img src={blog.image} alt={blog.title} />
                    <div className="blog-content">
                      <h4>{blog.title}</h4>
                      <h5>{blog.subtitle}</h5>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
            <br />
            <div className="text-center">
              <Link
                to="/Contactus"
                className="button-3 white-bg"
                onClick={scrollToTop}
              >
                Get a Quote
              </Link>
            </div>
          </div>
        </div> */}

        <div className="home-section-3-bg">
          <h2 className="line-1">Follow us on Instagram</h2>
          <div className="insta-gal">
            <ul>
              {instagallery.map((src, i) => (
                <li key={i} data-aos="zoom-out">
                  <img src={src} alt="" />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
