// GraniteInsidePage.js
import React from "react";
import { useParams } from "react-router-dom";
import GraniteinsidePageData from "./GraniteinsidePageData";

const GraniteInsidePage = () => {
  const { category, id } = useParams();
  const categoryData = GraniteinsidePageData[category];

  if (!categoryData) {
    return <p>Category not found</p>;
  }

  const pageData = categoryData.find((item) => item.id === parseInt(id));

  if (!pageData) {
    return <p>Item not found</p>;
  }

  return (
    <div>
      <div className="inner-banner-2">
        {pageData.banner && (
          <img
            src={pageData.banner}
            alt={`${pageData.heading} Banner`}
            className="banner-image"
          />
        )}
        <h2>{pageData.cat}</h2>
      </div>
      <div className="Product-content-wrap">
        <div className="wrapper">
          <div className="Product-content-2 ">
            <h1>{pageData.heading}</h1>
            <p>{pageData.description}</p>
          </div>

          <div className="g-gallery">
            {pageData.images.map((image, index) => (
              <div key={index} className="g-gallery-item">
                <img src={image.src} alt={image.alt} />
                <p>{image.imageName}</p> {/* Display custom image name */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraniteInsidePage;
