import React, { useState, useEffect } from "react";
import OnloadPopup from "./OnloadPopup";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import banner from "./images/about-us-banner.png";
import banner2 from "./images/gml-banner-new2.png";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  disable: isMobile,
});

const instagallery = [
  // require("./images/gml-1.png"),
  // require("./images/gml-2.png"),
  // require("./images/gml-3.png"),
  // require("./images/gml-4.png"),
  // require("./images/gml-5.png"),
  // require("./images/gml-6.png"),
  // require("./images/gml-7.png"),
  // require("./images/gml-8.png"),
  // require("./images/gml-9.png"),
  require("./images/gml-10.png"),
  require("./images/gml-11.png"),
  require("./images/gml-12.png"),
  require("./images/gml-13.png"),
  require("./images/gml-14.png"),
  require("./images/gml-15.png"),
  require("./images/gml-16.png"),
  require("./images/gml-17.png"),
  require("./images/gml-18.png"),
  require("./images/gml-19.png"),
  require("./images/gml-20.png"),
  require("./images/gml-21.png"),
  require("./images/gml-22.png"),
  require("./images/gml-23.png"),
  require("./images/gml-24.png"),
  require("./images/gml-25.png"),
];

function GML() {
  {
    /*Popup scripts start */
  }
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Show the popup after 30 seconds
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 5000); // Change to 30000 for 30 seconds

    // Clear the timer on cleanup
    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };
  {
    /*Popup scripts End */
  }
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: false, // Only animate once
      disable: () => window.innerWidth < 768, // Disable on mobile (screen width < 768px)
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      {/* {showPopup && <OnloadPopup closePopup={closePopup} />} */}
      <div>
        <div className="Banner">
          <img src={banner2} alt="" />
          <h1>Granite Mart Ltd</h1>
        </div>
        <div className="product-sec-1-wrap" data-aos="zoom-in-up">
          <div className="wrapper">
            {/* <h2>Granite Mart Ltd</h2> */}
            <p>
              Sy No. 161/162, Venkatapur Village, Brahmanpally Panchayat,
              Toopran Mandal, Medak District, Telangana- 502334
            </p>
          </div>
          <div className="wrapper">
            <div className="gallery">
              {instagallery.map((src, i) => (
                <img src={src} alt="" />
              ))}
            </div>
          </div>

          <div className="backbtn">
            <Link to="/Aboutus#ManufacturingFacilities" className="button-3">
              Back
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GML;
