import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import larrow from "./images/left-arrow-2.png";
import rarrow from "./images/right-arrow-2.png";

const images = [
  require("./images/home-banner-2.png"),
  require("./images/home-banner-3.png"),
  require("./images/home-banner-4.png"),
  require("./images/home-banner-5.png"),
  require("./images/home-banner-6.png"),
  require("./images/home-banner-7.png"),
  require("./images/home-banner-8.png"),
];

const titles = [
  "Kitchens with Quality Engineered for You ",
  "Wardrobes to suit every Space and Need",
  "Bathroom Vanities and Tops that embrace Elegance",
  "Office Tables that combine Form and Function",
  "Elevating your Living Spaces",
  "Timeless Natural Granite to match every Space and Design",
  "Exemplary Quartz Curated to your Needs",
];

function HomepageSlider() {
  const sliderRef = useRef(null); // Reference for the slider

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false, // Disable default arrows
    beforeChange: (current, next) => setActiveSlide(next),
  };

  const [activeSlide, setActiveSlide] = useState(0);

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div className="slider-home" id="homeslider">
      <Slider ref={sliderRef} {...settings}>
        {images.map((src, i) => (
          <div
            key={i}
            className={`slide ${i === activeSlide ? "zoom" : ""}`} // Apply zoom class to active slide
          >
            <img src={src} alt={`Slide ${i + 1}`} />
            {i === activeSlide && (
              <div className="banner-title">
                <h2>{titles[i]}</h2>
              </div>
            )}
          </div>
        ))}
      </Slider>
      <div className="slider-buttons">
        <img
          src={larrow}
          onClick={handlePrev}
          className="prev-button"
          alt="Previous"
        />
        <img
          src={rarrow}
          onClick={handleNext}
          className="next-button"
          alt="Next"
        />
      </div>
    </div>
  );
}

export default HomepageSlider;
