// InsidePage.js
import React from "react";
import { useParams } from "react-router-dom";
import EdgeProfilesinsidePageData from "./EdgeProfilesinsidePageData";

const EdgeProfilesInsidePage = () => {
  const { category, id } = useParams();
  const categoryData = EdgeProfilesinsidePageData[category];

  if (!categoryData) {
    return <p>Category not found</p>;
  }

  const pageData = categoryData.find((item) => item.id === parseInt(id));

  if (!pageData) {
    return <p>Item not found</p>;
  }

  return (
    <div>
      <div className="inner-banner-2">
        {pageData.banner && (
          <img
            src={pageData.banner}
            alt={`${pageData.heading} Banner`}
            className="banner-image"
          />
        )}
        <h2>{pageData.cat}</h2>
      </div>
      <div className="Product-content-wrap">
        <div className="wrapper">
          <div className="Product-content-1 ">
            <h1>{pageData.heading}</h1>
          </div>
          <div className="Products-inner-wrap">
            <div className="Products-inner-1">
              <h4>{pageData.subheading}</h4>
              <p>{pageData.description}</p>
              <h5>{pageData.subheading2}</h5>
            </div>
            <div className="Products-inner-2">
              {pageData.images.map((image, index) => (
                <img key={index} src={image.src} alt={image.alt} />
              ))}
            </div>
            <div className="Products-inner-2">
              {pageData.images.map((image, index) => (
                <img key={index} src={image.src} alt={image.alt} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EdgeProfilesInsidePage;
