import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import larrow from "./images/left-arrow.png";
import rarrow from "./images/right-arrow.png";

const images = [
  {
    src: require("./images/Finishers/img-1.png"),
    alt: "Polished",
    imageName: "Polished",
  },
  {
    src: require("./images/Finishers/img-2.png"),
    alt: "Honed",
    imageName: "Honed",
  },
  {
    src: require("./images/Finishers/img-3.png"),
    alt: "Flamed",
    imageName: "Flamed",
  },
  {
    src: require("./images/Finishers/img-4.png"),
    alt: "Leather",
    imageName: "Leather",
  },
  {
    src: require("./images/Finishers/img-5.png"),
    alt: "ICE",
    imageName: "ICE",
  },
];

function GraniteFinishesSlider() {
  const sliderRef = useRef(null); // Reference for the slider

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (current, next) => setActiveSlide(next),
    responsive: [
      {
        breakpoint: 1024, // For screens smaller than 1024px
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768, // For screens smaller than 768px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480, // For screens smaller than 480px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [activeSlide, setActiveSlide] = useState(0);

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <>
      <div className="slider-home">
        <Slider ref={sliderRef} {...settings}>
          {images.map((image, i) => (
            <div
              key={i}
              className={`slide ${i === activeSlide ? "zoom" : ""}`} // Apply zoom class to active slide
            >
              <img src={image.src} alt={image.name} />
              <p className="image-name">{image.imageName}</p>
            </div>
          ))}
        </Slider>
        <div className="slider-buttons">
          <img src={larrow} onClick={handlePrev} className="prev-button" />
          <img src={rarrow} onClick={handleNext} className="next-button" />
        </div>
      </div>
    </>
  );
}

export default GraniteFinishesSlider;
