//colour Range page Quartz Data Page 2

const QuartzInsidepage = {

  ColourRange: {
    id: "ColourRange", 
    description:
      "dgfdffLorem Ipsum is simply dummy text of the printing and typesetting industry.",
    banner: "/images/Granite-banner.png",
    images: [
      {
        src: `/images/ColourRange/Argento.png`,
        alt: "Argento",
        imageName: "Argento",
      },
      {
        src: `/images/ColourRange/Bronzo.png`,
        alt: "Bronzo",
        imageName: "Bronzo",
      },
      {
        src: `/images/ColourRange/Cobalto.png`,
        alt: "Cobalto",
        imageName: "Cobalto",
      },
      {
        src: `/images/ColourRange/Oro.png`,
        alt: "Oro",
        imageName: "Oro",
      },
      {
        src: `/images/ColourRange/Palladio.png`,
        alt: "Palladio",
        imageName: "Palladio",
      },
      {
        src: `/images/ColourRange/Platino.png`,
        alt: "Platino",
        imageName: "Platino",
      },
    ],
  },
  
}
 
export default QuartzInsidepage