import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";

function OnloadPopup({ closePopup }) {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear error on input change
  };

  const validateForm = () => {
    const newErrors = { ...errors }; // Retain existing errors
  
    // First Name validation (required, at least 2 characters)
    if (!formData.firstname.trim()) {
      newErrors.firstname = "First name is required.";
    } else if (formData.firstname.length < 2) {
      newErrors.firstname = "First name must be at least 2 characters.";
    } else {
      delete newErrors.firstname; // Clear previous error if validation passes
    }
  
    // Last Name validation (optional, at least 2 characters if provided)
    if (!formData.lastname && formData.lastname.length < 2) {
      newErrors.lastname = "Last name is required.";
    } else {
      delete newErrors.lastname;
    }
  
    // Email validation (valid email format, optional)
    if (!formData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Enter a valid email address.";
    } else {
      delete newErrors.email;
    }
  
    // Phone validation (required, numeric, at least 10 digits)
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required.";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Enter a valid 10-digit phone number.";
    } else {
      delete newErrors.phone;
    }
  
    // Message validation (optional, max 500 characters)
    if (formData.message && formData.message.length > 500) {
      newErrors.message = "Message cannot exceed 500 characters.";
    } else {
      delete newErrors.message;
    }
  
    setErrors(newErrors); // Update all errors
    return Object.keys(newErrors).length === 0; // Return validation status
  };
  

  const handleSubmit = async () => {
    if (!validateForm()) {
      toast.error("Please fill the form before submitting.");
      return;
    }

    try {
      const response = await fetch("https://rostre-web.psmprojects.net/contact-form.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to submit the form. Please try again.");
      }

      const result = await response.json();
      toast.success("Form submitted successfully!");
      setTimeout(() => {
        closePopup(); // Close popup after success
      }, 1000);
      console.log("Response:", result);
    } catch (error) {
      toast.error("An error occurred: " + error.message);
    }
  };

  return (
    <div className="popup">
      <Toaster /> {/* Toast container */}
      <div className="popup-content">
        <h2 className="text-center-1">Get a Free Design Consult</h2>
        <div className="close-btn-sec">
          <button onClick={closePopup} className="close-btn-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
            >
              <path
                d="M30 10L10 30M10 10L30 30"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <div className="popup-form-wrap">
          <div className="popup-form-1">
            <input
              type="text"
              name="firstname"
              placeholder="First Name*"
              className="text-filed-1"
              value={formData.firstname}
              onChange={handleChange}
            />
            {errors.firstname && <p style={{ color: "red" }}>{errors.firstname}</p>}
          </div>
          <div className="popup-form-1">
            <input
              type="text"
              name="lastname"
              placeholder="Last Name"
              className="text-filed-1"
              value={formData.lastname}
              onChange={handleChange}
            />
            {errors.lastname && <p style={{ color: "red" }}>{errors.lastname}</p>}
          </div>
          <div className="popup-form-1">
            <input
              type="text"
              name="phone"
              placeholder="Phone Number*"
              className="text-filed-1"
              value={formData.phone}
              onChange={handleChange}
            />
            {errors.phone && <p style={{ color: "red" }}>{errors.phone}</p>}
          </div>
          <div className="popup-form-1">
            <input
              type="text"
              name="email"
              placeholder="Email"
              className="text-filed-1"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
          </div>
          <div className="popup-form-1 w-100">
            <textarea
              name="message"
              className="text-area-1"
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
            />
            {errors.message && <p style={{ color: "red" }}>{errors.message}</p>}
          </div>
          <div className="popup-form-1 w-100 text-center-1">
            <button onClick={handleSubmit} className="button-4">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnloadPopup;
