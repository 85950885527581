import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import larrow from "./images/left-arrow.png";
import rarrow from "./images/right-arrow.png";

const images = [
  require("./images/sup-1.png"),
  require("./images/sup-2.png"),
  require("./images/sup-3.png"),
  require("./images/sup-4.png"),
  require("./images/sup-5.png"),
  require("./images/sup-6.png"),
  require("./images/sup-7.png"),
  require("./images/sup-8.png"),
  require("./images/sup-9.png"),
  require("./images/sup-10.png"),
  require("./images/sup-11.png"),
  require("./images/sup-12.png"),
];

function SupplierCollaborations() {
  const sliderRef = useRef(null); // Reference for the slider

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: false,
    beforeChange: (current, next) => setActiveSlide(next),
    responsive: [
      {
        breakpoint: 1024, // For screens smaller than 1024px
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768, // For screens smaller than 768px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480, // For screens smaller than 480px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [activeSlide, setActiveSlide] = useState(0);

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div className="slider-home">
      <Slider ref={sliderRef} {...settings}>
        {images.map((src, i) => (
          <div
            key={i}
            className={`slide ${i === activeSlide ? "zoom" : ""}`} // Apply zoom class to active slide
          >
            <img src={src} alt={`Slide ${i + 1}`} />
          </div>
        ))}
      </Slider>
      <div className="slider-buttons">
        {/* <button onClick={handlePrev} className="prev-button">
          Previous
        </button>
        <button onClick={handleNext} className="next-button">
          Next
        </button> */}
        <img src={larrow} onClick={handlePrev} className="prev-button" />
        <img src={rarrow} onClick={handleNext} className="next-button" />
      </div>
    </div>
  );
}

export default SupplierCollaborations;
