import React, { useState, useEffect } from "react";
import OnloadPopup from "./OnloadPopup";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import banner from "./images/Granite-banner-2.png";
import divinelogo from "./images/gloval-logo-32.png";
import Section2 from "./images/GraniteGlobal-1.png";
import Section4 from "./images/GraniteGlobal-1a.png";
import icon1 from "./images/call-1-1.png";
import icon2 from "./images/email-1-1.png";
import icon3 from "./images/whatsup-1-1.png";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  disable: isMobile,
});

const instagallery = [
  require("./images/GraniteGlobal-2.png"),
  require("./images/GraniteGlobal-3.png"),
];

function GraniteGlobal() {
  {
    /*Popup scripts start */
  }
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Show the popup after 30 seconds
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 5000); // Change to 30000 for 30 seconds

    // Clear the timer on cleanup
    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };
  {
    /*Popup scripts End */
  }
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: false, // Only animate once
      disable: () => window.innerWidth < 768, // Disable on mobile (screen width < 768px)
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const phoneNumber = "9177444288"; // Replace with the recipient's phone number in international format (no '+' or leading '00')
  const message = "Hello, I'm interested in your services!"; // Pre-filled message

  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message
  )}`;
  return (
    <div>
      {/* {showPopup && <OnloadPopup closePopup={closePopup} />} */}
      <div>
        <div className="Banner">
          <img src={banner} alt="" />
          <h1>Granite</h1>
        </div>
        <div className="product-sec-1-wrap" data-aos="zoom-in-up">
          <div className="wrapper">
            <div className="divinelogo">
              <img src={divinelogo} alt="Section1" data-aos="zoom-in-up" />
            </div>
            <p>
              Granite Mart Ltd is a leading exporter of granite slabs and cut to
              size products from India, specializing in the manufacture of
              slabs, prefabricated products, and countertops. The factory is
              conveniently located close to major raw material sources in
              Toopran, off Hyderabad, in Telangana, India.
            </p>
          </div>

          <div className="wrapper">
            <div className="global-section-1-bg">
              <div className="wrapper">
                <div className="global-section-1-wrap">
                  <div className="global-section-1-1">
                    <img src={Section2} alt="Section12" data-aos="zoom-in-up" />
                  </div>
                  <div className="global-section-1-2" data-aos="fade-up-left">
                    <p class="text-left-1">
                      The company has been successfully exporting their products
                      over the last 30 years to high-end distributors in the
                      USA, Canada, Australia, New Zealand and Europe. Our
                      state-of-the-art production facility in Hyderabad is
                      equipped with modern granite processing machinery all
                      imported from Italy :
                    </p>
                    <div className="bullet-2 bullet-2a">
                      <ul>
                        <li>Multi wire saw</li>
                        <li>2 Automatic Line Polishers for slabs</li>
                        <li>
                          4 Bridge Saws for large scale cut to size projects
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <br />
                <p>
                  {" "}
                  We can also supply granite tiles and slabs with other types of
                  surface finish; such as flamed, honed, ICE and brush finish,
                  apart from the mirror finish. We also have a fully automated
                  CNC Line to produce accurate size worktops as per your
                  specifications. We can supply all these products in a wide
                  range of Indian granite, such as Black Galaxy, Absolute Black,
                  Tan Brown, Saphire Blue, Black Pearl, Silver Pearl, Viscount
                  White, and other Indian colours at attractive prices and
                  on-time schedules.
                </p>{" "}
                <br />
                <div className="global-section-1-wrap">
                  <div className="global-section-1-2" data-aos="fade-up-left">
                    <p class="text-left-1">
                      The manufactured product is subject to stringent quality
                      control at every stage of process, ensuring dimensional
                      accuracy and perfect finish. Some of the aspects that are
                      unique to our company are :
                    </p>
                    <div className="bullet-2 bullet-2a">
                      <ul>
                        <li>
                          We can do all kinds of cut to size and prefabs like
                          kitchen islands, vanities, shampoo stands, thresholds,
                          corner shelves and much more
                        </li>
                        <li>
                          All kinds of edges possible including laminated and
                          miter edge
                        </li>
                        <li>
                          Tie up with raw material vendors from all parts of
                          India to provide a wider range of colours
                        </li>
                        <li>Quick turnaround time</li>
                      </ul>
                    </div>
                  </div>
                  <div className="global-section-1-1">
                    <img src={Section4} alt="Section12" data-aos="zoom-in-up" />
                  </div>
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="gallery-2">
                {instagallery.map((src, i) => (
                  <img src={src} alt="" />
                ))}
              </div>
            </div>
            <br />
            <h2>For more details please</h2>
            <div className="connect-sec-wrap">
              <div className="connect-sec-1">
                <Link to="/Contactus" onClick={scrollToTop}>
                  <img src={icon1} alt="" />
                </Link>
              </div>
              <div className="connect-sec-1">
                <a
                  href="mailto:info@granitemart.in"
                  target="_blank"
                  onClick={scrollToTop}
                >
                  <img src={icon2} alt="" />
                </a>
              </div>
              <div className="connect-sec-1">
                <a
                  href={whatsappLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={icon3} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GraniteGlobal;
