// GraniteinsidePageData.js

const GraniteinsidePageData = {
  ProductCategories: [
    {
      id: 1,
      cat: "Granite",
      heading: "Colours",
      description:
        "We offer a wide range of colours sourced from quarries all over the country. They are available in Polished, Flamed, Honed and Leather finishes. ",
      banner: "/images/Granite-banner.png",
      images: [
        {
          src: `/images/Granite/Granite-img-1.png`,
          alt: "Black",
          imageName: "Black",
        },
        {
          src: `/images/Granite/Granite-img-2.png`,
          alt: "Blues",
          imageName: "Blues",
        },
        {
          src: `/images/Granite/Granite-img-3.png`,
          alt: "Product Image 3",
          imageName: "Browns and Reds",
        },
        {
          src: `/images/Granite/Granite-img-4.png`,
          alt: "Product Image 4",
          imageName: "Greens",
        },
        {
          src: `/images/Granite/Granite-img-5.png`,
          alt: "Product Image 5",
          imageName: "Marbles",
        },
        {
          src: `/images/Granite/Granite-img-6.png`,
          alt: "Product Image 6",
          imageName: "Red and Whites",
        },
        {
          src: `/images/Granite/Granite-img-7.png`,
          alt: "Product Image 7",
          imageName: "Yellow",
        },
        {
          src: `/images/Granite/Granite-img-8.png`,
          alt: "Product Image 8",
          imageName: "Yellows and Brown",
        },
        {
          src: `/images/Granite/Granite-img-9.png`,
          alt: "Product Image 9",
          imageName: "Yellows and Creams",
        },
      ],
    },
    {
      id: 2,
      heading: "content will be posted soon..",
      cat: "Granite",
      description: "",
      banner: "/images/Modular-F-kitchen.png",
      images: Array.from({ length: 0 }, (_, i) => ({
        src: `/images/kitchen/pg-h-${i + 1}.png`, // Correct path from the public folder
        alt: `Product Category 1 - Image ${i + 1}`,
      })),
    },

    {
      id: 3,
      cat: "Granite",
      heading: "Finishes",
      description:
        "Select from various finishes—polished, honed, or textured—to achieve the desired look and feel for your countertops and vanities.",
      banner: "/images/Granite-banner.png",
      images: [
        {
          src: `/images/Finishers/img-1.png`,
          alt: "Polished",
          imageName: "Polished",
        },
        {
          src: `/images/Finishers/img-2.png`,
          alt: "Honed",
          imageName: "Honed",
        },
        {
          src: `/images/Finishers/img-3.png`,
          alt: "Flamed",
          imageName: "Flamed",
        },
        {
          src: `/images/Finishers/img-4.png`,
          alt: "Leather",
          imageName: "Leather",
        },
      ],
    },

    // Add more items as needed
  ],
  MaterialOptions: [
    {
      id: 1,
      heading: "Granite",
      cat: "Granite",
      banner: "/images/Modular-F-kitchen.png",
      description: "Description for Material Option 1",
      images: Array.from({ length: 6 }, (_, i) => ({
        src: `./images/kitchen/pg-h-${i + 1}.png`, // Use actual image paths
        alt: `Material Option 1 - Image ${i + 1}`,
      })),
    },
    {
      id: 2,
      heading: "Material Option 2",
      cat: "Modular Furniture",
      banner: "/images/banner-1.png",
      description: "Description for Material Option 2",
      images: Array.from({ length: 6 }, (_, i) => ({
        src: `./images/kitchen/pg-h-${i + 1}.png`,
        alt: `Material Option 2 - Image ${i + 1}`,
      })),
    },

    {
      id: 3,
      heading: "Finishes",
      cat: "Finishes",
      banner: "/images/Modular-F-kitchen.png",
      description: "Description for Material Option 1",
      images: Array.from({ length: 6 }, (_, i) => ({
        src: `./images/kitchen/pg-h-${i + 1}.png`, // Use actual image paths
        alt: `Material Option 1 - Image ${i + 1}`,
      })),
    },

    // Add more items as needed
  ],
};

export default GraniteinsidePageData;
