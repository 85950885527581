import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";

import { Route, Routes } from "react-router-dom";

import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Home from "./Components/Home";

import ModularFurniture from "./Components/ModularFurniture";
import OurProcess from "./Components/OurProcess";
import Aboutus from "./Components/Aboutus";
import Export from "./Components/Export";
import Projects from "./Components/Projects";
import WhyChooseRostre from "./Components/WhyChooseRostre";
import Contactus from "./Components/Contactus";
import EdgeProfiles from "./Components/EdgeProfiles";
import Quartz from "./Components/Quartz";
import Granite from "./Components/Granite";
import ModularFurnitureInsidePage from "./Components/ModularFurnitureInsidePage";
import GraniteInsidePage from "./Components/GraniteInsidePage";
import QuartzProductsInsidePage from "./Components/QuartzProductsInsidePage";
import EdgeProfilesInsidePage from "./Components/EdgeProfilesInsidePage";

import QuartzProducts from "./Components/QuartzProducts";
import BlogDetailPage from "./Components/BlogDetailPage";
import Blog from "./Components/Blog";
import RostreCabinets from "./Components/RostreCabinets";
import GraniteGlobal from "./Components/GraniteGlobal";
import RostreCabinetsGlobal from "./Components/RostreCabinetsGlobal";
import DivineSpaces from "./Components/DivineSpaces";
import DivineSpacesGlobal from "./Components/DivineSpacesGlobal";
import GML from "./Components/GML";

//quartz-pages
import QuartzInsidePage from "./Components/QuartzInsidePage";
import QuartzItemPage from "./Components/QuartzSubDataPage";
import ColourRangeDetailsPage from "./Components/ColourRangeDetailsPage ";

// categories.js
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  disable: isMobile,
});

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: false, // Only animate once
      disable: () => window.innerWidth < 768, // Disable on mobile (screen width < 768px)
    });
  }, []);
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route element={<Home />} path="/" />
        {/* <Route element={<InsidePage />} path="/InsidePage" /> */}
        <Route path="/ModularFurniture" element={<ModularFurniture />} />
        <Route
          path="/ModularFurniture/:category/:id"
          element={<ModularFurnitureInsidePage />}
        />
        <Route path="/Granite" element={<Granite />} />
        <Route path="/Aboutus" element={<Aboutus />} />
        <Route path="/Projects" element={<Projects />} />
        <Route path="/Export" element={<Export />} />
        <Route path="/WhyChooseRostre" element={<WhyChooseRostre />} />

        <Route path="/Contactus" element={<Contactus />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/Granite/:category/:id" element={<GraniteInsidePage />} />

        <Route path="/OurProcess" element={<OurProcess />} />
        <Route path="/EdgeProfiles" element={<EdgeProfiles />} />
        <Route path="/Quartz" element={<Quartz />} />
        <Route path="/QuartzProducts" element={<QuartzProducts />} />
        <Route path="/blog/:id" element={<BlogDetailPage />} />
        <Route
          path="/QuartzProducts/:category/:id"
          element={<QuartzProductsInsidePage />}
        />

        <Route
          path="/EdgeProfiles/:category/:id"
          element={<EdgeProfilesInsidePage />}
        />

        <Route path="/quartz/:categoryName" element={<QuartzInsidePage />} />
        <Route
          path="/Quartz/:categoryName/:itemName"
          element={<QuartzItemPage />}
        />

        <Route
          path="/Quartz/ColourRange/:categoryName/:itemName/Details"
          element={<ColourRangeDetailsPage />}
        />
        <Route path="/RostreCabinets" element={<RostreCabinets />} />
        <Route path="/GML" element={<GML />} />
        <Route path="/DivineSpaces" element={<DivineSpaces />} />
        <Route path="/DivineSpacesGlobal" element={<DivineSpacesGlobal />} />
        <Route
          path="/RostreCabinetsGlobal"
          element={<RostreCabinetsGlobal />}
        />
        <Route path="/GraniteGlobal" element={<GraniteGlobal />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
