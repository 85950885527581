import React, { useState, useEffect } from "react";
import OnloadPopup from "./OnloadPopup";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import banner from "./images/about-us-banner.png";
import Section1 from "./images/Section-1-img.png";
import aboutimg1 from "./images/about-img-1.png";
import aboutthumb from "./images/about-thumb-1.png";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  disable: isMobile,
});
const photogallery = [
  require("./images/pg-h-1.png"),
  require("./images/pg-h-2.png"),
  require("./images/pg-h-3.png"),
  require("./images/pg-h-4.png"),
  require("./images/pg-h-5.png"),
  require("./images/pg-h-6.png"),
  require("./images/pg-h-7.png"),
  require("./images/pg-h-8.png"),
];

const instagallery = [
  require("./images/insta-h-1.png"),
  require("./images/insta-h-2.png"),
  require("./images/insta-h-3.png"),
  require("./images/insta-h-4.png"),
  require("./images/insta-h-5.png"),
  require("./images/insta-h-6.png"),
  require("./images/insta-h-7.png"),
  require("./images/insta-h-8.png"),
  require("./images/insta-h-7.png"),
  require("./images/insta-h-8.png"),
];

function WhyChooseRostre() {
  {
    /*Popup scripts start */
  }
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Show the popup after 30 seconds
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 5000); // Change to 30000 for 30 seconds

    // Clear the timer on cleanup
    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };
  {
    /*Popup scripts End */
  }
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: false, // Only animate once
      disable: () => window.innerWidth < 768, // Disable on mobile (screen width < 768px)
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      {/* {showPopup && <OnloadPopup closePopup={closePopup} />} */}
      <div>
        <div className="Banner">
          <img src={banner} alt="" />
          <h1>Legacy that continues to unfold and innovate</h1>
        </div>
        <div className="about-section-1-bg  ">
          <div className="wrapper">
            <div className="home-section-1-wrap">
              <div
                className="home-section-1-2 WhyChooseRostre"
                data-aos="fade-up-left"
              >
                <h2>Honesty and Transparency</h2>
                <p>
                  We believe in building trust with our customers. Our
                  commitment is to promise only what we can deliver, ensuring
                  transparency in every interaction.
                </p>
                <h2>In-House Manufacturing </h2>
                <p>
                  Unlike many retailers, we take pride in our own manufacturing
                  facility. This allows us to maintain strict quality control
                  and ensure that every piece of furniture meets our high
                  standards.
                </p>
                <h2>Space-Saving Solutions</h2>
                <p>
                  Our innovative space-saving modules are designed to maximize
                  every inch of your living and working area, allowing you to
                  fit more functionality and style into the confines of your
                  space.
                </p>
                <h2>Precision Engineering</h2>
                <p>
                  Our state-of-the-art automated facility features advanced
                  German machinery and barcoded processes that ensure precision
                  in every detail of our products.
                </p>
                <h2>Collaborations with Top Global Brands </h2>
                <p>
                  Rostre Spaces collaborates with top brands such as Hettich,
                  Hafele, Blum, and William Sherman to ensure that our product
                  quality remains unmatched.
                </p>
                <h2>Seamless Experience</h2>
                <p>
                  Our process is designed with you in mind. From your first call
                  to design, quotation, production, and installation, our expert
                  team manages every step in-house, providing a seamless
                  experience.
                </p>
                <h2>Exceptional Quality</h2>
                <p>
                  Quality is at the heart of what we do. We deliver top-notch
                  craftsmanship using premium materials, all subjected to
                  rigorous inspections to guarantee durability and aesthetic
                  appeal.
                </p>
                <h2>Innovative Design</h2>
                <p>
                  Stay ahead of the trends with our innovative designs that
                  incorporate the latest styles and technologies. Our furniture
                  not only looks great but also adapts to your changing needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyChooseRostre;
