import React, { useState, useEffect } from "react";
import OnloadPopup from "./OnloadPopup";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import banner from "./images/DivineSpacesGlobalBanner.png";
import Section1 from "./images/global-thumb-1.png";
import divinelogo from "./images/divine-logo.png";
import Section2 from "./images/Global-img2.png";
import aboutthumb from "./images/global-products-1.png";
import aboutthumb1 from "./images/global-products-2.png";
import aboutthumb2 from "./images/global-products-3.png";

import gimg1 from "./images/gloval-logo-1.png";
import gimg2 from "./images/gloval-logo-3-white.png";
import gimg3 from "./images/gloval-logo-2.png";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  disable: isMobile,
});

const laminates = [
  require("./images/Laminates-1.png"),
  require("./images/Laminates-2.png"),
];

const thermofoil = [
  require("./images/thermofoil-1.png"),
  require("./images/thermofoil-2.png"),
];
const acrylic = [
  require("./images/acrylic-1.png"),
  require("./images/acrylic-2.png"),
];

const oc = [
  require("./images/Our-Cabinets-1.png"),
  require("./images/Our-Cabinets-2.png"),
];

const colouroptions = [
  require("./images/ColourOptions-1.png"),
  require("./images/ColourOptions-2.png"),
  require("./images/ColourOptions-3.png"),
  require("./images/ColourOptions-4.png"),
  require("./images/ColourOptions-5.png"),
  require("./images/ColourOptions-6.png"),
  require("./images/ColourOptions-7.png"),
  require("./images/ColourOptions-8.png"),
];

const co2 = [
  require("./images/co2-1.png"),
  require("./images/co2-2.png"),
  require("./images/co2-3.png"),
  require("./images/co2-4.png"),
];
const paint = [
  require("./images/paint-1.png"),
  require("./images/paint-2.png"),
  require("./images/paint-3.png"),
  require("./images/paint-4.png"),
];

const co3 = [
  require("./images/co3-1.png"),
  require("./images/co3-2.png"),
  require("./images/co3-3.png"),
  require("./images/co3-4.png"),
  require("./images/co3-5.png"),
  require("./images/co3-6.png"),
];

const co4 = [
  require("./images/co4-1.png"),
  require("./images/co4-2.png"),
  require("./images/co4-3.png"),
  require("./images/co4-4.png"),
];

function Export() {
  {
    /*Popup scripts start */
  }
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Show the popup after 30 seconds
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 5000); // Change to 30000 for 30 seconds

    // Clear the timer on cleanup
    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };
  {
    /*Popup scripts End */
  }
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: false, // Only animate once
      disable: () => window.innerWidth < 768, // Disable on mobile (screen width < 768px)
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      {/* {showPopup && <OnloadPopup closePopup={closePopup} />} */}
      <div>
        <div className="Banner">
          <img src={banner} alt="" />
          {/* <h1>Projects</h1> */}
        </div>
        <div className="about-section-1-bg  ">
          <div className="wrapper">
            {/* <div className="divinelogo">
              <img src={divinelogo} alt="Section1" data-aos="zoom-in-up" />
            </div> */}
            <div>
              <h2 className="text-center line-3">
                About Rostre Group of Companies
              </h2>
              <p>
                For over 30 years, our group of companies has been a trusted
                supplier of building materials for projects and multifamily
                homes, serving distributors, fabricators, and wholesalers across
                the United States, Canada, Europe, and the UAE. Based in
                Hyderabad, India, Rostre Group provides a comprehensive one-stop
                solution for builder services.
              </p>
            </div>
            <div className="home-section-1-wrap">
              <div className="home-section-1-1">
                <img src={Section1} alt="Section1" data-aos="zoom-in-up" />
              </div>
              <div
                className="home-section-1-2 global-s-1"
                data-aos="fade-up-left"
              >
                <h3>Our Offerings</h3>
                <h4>Divine Surfaces</h4>
                <p>
                  As India's first and only quartz slab manufacturing unit
                  focused exclusively on cut-to-size products, Divine Surfaces
                  ensures premium quality with a fully automated pressing line.
                  Our state-of-the-art technology allows us to produce
                  consistent slabs tailored for projects, minimizing defects and
                  ensuring rapid turnaround times.
                </p>
                <h4>Rostre Cabinet </h4>
                <p>
                  Specializing in modular kitchens, wardrobes, and vanities,
                  Rostre Cabinet caters specifically to projects and multifamily
                  homes. Our commitment to exceptional quality and design makes
                  us the ideal partner for your cabinetry needs.
                </p>
                <h4>Granite Mart Ltd</h4>
                <p>
                  A leader in quarrying, processing, and exporting granite slabs
                  since 2003, Granite Mart Ltd offers high-quality cut-to-size
                  granite solutions. Our extensive experience in the industry
                  ensures that we deliver superior products that meet the
                  demands of any project.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="about-bg-3 gray-bg-2">
          <div className="wrapper" data-aos="zoom-in-up">
            <h2 className="line-4">Our Products</h2>
            <div className="category-images">
              <Link to="/DivineSpacesGlobal" onClick={scrollToTop}>
                <img src={aboutthumb1} alt="aboutthumb" data-aos="zoom-in-up" />
                <div className="catname">
                  <img src={gimg1} alt="gimg1"></img>
                </div>
              </Link>
              <Link to="/GraniteGlobal" onClick={scrollToTop}>
                <img src={aboutthumb2} alt="aboutthumb" data-aos="zoom-in-up" />
                <div className="catname">
                  {" "}
                  <img src={gimg2} alt="gimg1"></img>
                </div>
              </Link>
              <Link to="/RostreCabinetsGlobal" onClick={scrollToTop}>
                <img src={aboutthumb} alt="aboutthumb" data-aos="zoom-in-up" />
                <div className="catname">
                  {" "}
                  <img src={gimg3} alt="gimg1"></img>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Export;
