// insidePageData.js

const EdgeProfilesInsidePageData = {
  ProductCategories: [
    {
      id: 1,
      cat: "Quartz",
      heading: "Laminated",
      subheading: "Product Details",
      subheading2: "",
      description: "",
      banner: "/images/Quartz-banner.png",
      images: Array.from({ length: 1 }, (_, i) => ({
        // src: `/images/kitchen/img-${i + 1}.png`, // Correct path from the public folder
        src: `/images/Laminated-thumb.png`,
        alt: `Islands - Image ${i + 1}`,
      })),
    },
    {
      id: 2,
      cat: "Quartz",
      heading: "Mitered",
      subheading: "Product Details",
      subheading2: "",
      description: "",
      banner: "/images/Quartz-banner.png",
      images: Array.from({ length: 1 }, (_, i) => ({
        //src: `/images/kitchen/img-${i + 1}.png`, // Correct path from the public folder
        src: `/images/Window-Sills-thumb.png`,
        alt: `Islands - Image ${i + 1}`,
      })),
    },
    {
      id: 3,
      cat: "Quartz",
      heading: "Standard",
      subheading: "Standard",
      subheading2:
        "Can be done in all colors we carry as seen in our slab section and we can even make custom colors for large projects .",
      description:
        "Bevel, Demi-Bullnose, Double-Eased-Edge, Double-Pencil-Round, Double-Radius, Eased-Edge, Full-Bullnose, Half-Bullnose, Knife-Edge, Pencil-Round, Radius",
      banner: "/images/Quartz-banner.png",
      images: Array.from({ length: 1 }, (_, i) => ({
        //src: `/images/kitchen/img-${i + 1}.png`, // Correct path from the public folder
        src: `/images/Standard-thumb.png`,
        alt: `Islands - Image ${i + 1}`,
      })),
    },
  ],
};

export default EdgeProfilesInsidePageData;
