import React, { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import banner from "./images/contact-banner.png";

function Contactus() {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.firstname.trim()) {
      newErrors.firstname = "First name is required.";
    } else if (formData.firstname.length < 2) {
      newErrors.firstname = "First name must be at least 2 characters.";
    }

    if (!formData.lastname && formData.lastname.length < 2) {
      newErrors.lastname = "Last name is required.";
    }

    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required.";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Enter a valid 10-digit phone number.";
    }

    if (!formData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Enter a valid email address.";
    }

    if (formData.message && formData.message.length > 500) {
      newErrors.message = "Message cannot exceed 500 characters.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch(
        "https://rostre-web.psmprojects.net/contact-form.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the form. Please try again.");
      }

      const result = await response.json();
      toast.success("Form submitted successfully!");

      console.log("Response:", result);

      setFormData({
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        message: "",
      });
    } catch (error) {
      toast.error("An error occurred: " + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <div className="Banner">
        <img src={banner} alt="Contact Banner" />
        <h1>
          Looking forward to collaborating with you to create the space of your
          dreams!
        </h1>
      </div>
      <div className="contact-bg">
        <div className="wrapper">
          <div className="contact-wrap">
            <div className="contact-1">
              <h2 className="left-line-small">Get In Touch</h2>
              <div className="cnt-a-1 cnt-f-phone">+91 9000014692</div>
              <div className="cnt-a-1 cnt-f-mail">info@rostre.in</div>
              <div className="cnt-a-1 cnt-f-location">
                N Square, 3rd Floor, <br />
                Opp. N Convention,
                <br /> Hitech City Main Rd, <br />
                Hyderabad, <br />
                Telangana- 500081
              </div>
            </div>
            <div className="contact-2">
              <input
                type="text"
                name="firstname"
                placeholder="First Name*"
                className="text-filed-1"
                value={formData.firstname}
                onChange={handleChange}
              />
              {errors.firstname && (
                <p style={{ color: "red" }}>{errors.firstname}</p>
              )}
              <input
                type="text"
                name="lastname"
                placeholder="Last Name"
                className="text-filed-1"
                value={formData.lastname}
                onChange={handleChange}
              />
              {errors.lastname && (
                <p style={{ color: "red" }}>{errors.lastname}</p>
              )}
              <input
                type="text"
                name="phone"
                placeholder="Phone Number*"
                className="text-filed-1"
                value={formData.phone}
                onChange={handleChange}
              />
              {errors.phone && <p style={{ color: "red" }}>{errors.phone}</p>}
              <input
                type="text"
                name="email"
                placeholder="Email"
                className="text-filed-1"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
              <textarea
                name="message"
                placeholder="Message"
                className="text-area-1"
                value={formData.message}
                onChange={handleChange}
              />
              {errors.message && (
                <p style={{ color: "red" }}>{errors.message}</p>
              )}
              <button
                className="button-4"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
              <Toaster position="center" />
            </div>
          </div>
        </div>

        <div className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1745.8831364378004!2d78.38169444180586!3d17.456565365318237!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9319fc77509f%3A0x41607df570f01212!2sN%20Square!5e1!3m2!1sen!2sin!4v1732630712923!5m2!1sen!2sin"
            width="100%"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Contactus;
