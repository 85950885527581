import React, { useState, useEffect } from "react";
import OnloadPopup from "./OnloadPopup";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import banner from "./images/divinbanner.png";
import { useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  disable: isMobile,
});

const instagallery = [
  require("./images/rc-3.png"),
  require("./images/rc-5.png"),
  require("./images/rc-6.png"),
  require("./images/rc-7.png"),
  require("./images/rc-11.png"),
  require("./images/rc-12.png"),
  require("./images/rc-13.png"),
  require("./images/rc-14.png"),
  require("./images/rc-15.png"),
  require("./images/rc-16.png"),
  require("./images/rc-17.png"),
  require("./images/rc-18.png"),
];

function DivineSpaces() {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Show the popup after 30 seconds
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 5000); // Change to 30000 for 30 seconds

    // Clear the timer on cleanup
    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };
  {
    /*Popup scripts End */
  }
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: false, // Only animate once
      disable: () => window.innerWidth < 768, // Disable on mobile (screen width < 768px)
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      {/* {showPopup && <OnloadPopup closePopup={closePopup} />} */}
      <div>
        <div className="Banner">
          <img src={banner} alt="" />
          <h1>Divine Surfaces</h1>
        </div>
        <div className="product-sec-1-wrap" data-aos="zoom-in-up">
          <div className="wrapper">
            <p>
              Sy # 46A, Balanagar Village, Mahabubnagar Dist 509202 (Off
              Hyderabad)
            </p>
          </div>
          <div className="wrapper">
            <div className="gallery">
              {instagallery.map((src, i) => (
                <img src={src} alt="" />
              ))}
            </div>
          </div>

          <div className="backbtn">
            <Link to="/Aboutus#ManufacturingFacilities" className="button-3">
              Back
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DivineSpaces;
