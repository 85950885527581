import React, { useState, useEffect } from "react";
import OnloadPopup from "./OnloadPopup";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import banner from "./images/about-us-banner.png";
import Section1 from "./images/Section-1-img.png";
import warrantyimg1 from "./images/warrantyimg1.png";
import warrantyimg2 from "./images/warrantyimg2.png";
import warrantyimg3 from "./images/warrantyimg3.png";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  disable: isMobile,
});
function Warranty() {
  {
    /*Popup scripts start */
  }
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Show the popup after 30 seconds
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 5000); // Change to 30000 for 30 seconds

    // Clear the timer on cleanup
    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };
  {
    /*Popup scripts End */
  }
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: false, // Only animate once
      disable: () => window.innerWidth < 768, // Disable on mobile (screen width < 768px)
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div className="warranty-wrap">
        <div className="warranty-1">
          <img src={warrantyimg1} alt="" />
          <h5>Personalised Designs</h5>
        </div>
        <div className="warranty-1">
          <img src={warrantyimg2} alt="" />
          <h5>Flat 10 year Warranty</h5>
        </div>
        <div className="warranty-1">
          <img src={warrantyimg3} alt="" />
          <h5>Transparent Pricing</h5>
        </div>
      </div>
    </div>
  );
}

export default Warranty;
