import React, { useState, useEffect } from "react";
import OnloadPopup from "./OnloadPopup";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import banner from "./images/DivineSpacesGlobalbanner2.png";
import divinelogo from "./images/divine-logo.png";
import Section2 from "./images/DivineSpacesGlobal.png";
import icon1 from "./images/call-1-1.png";
import icon2 from "./images/email-1-1.png";
import icon3 from "./images/whatsup-1-1.png";
import icon4 from "./images/web.png";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  disable: isMobile,
});

const instagallery = [
  require("./images/DivineSpacesGlobal1.png"),
  require("./images/DivineSpacesGlobal2.png"),
];

function DivineSpacesGlobal() {
  {
    /*Popup scripts start */
  }
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Show the popup after 30 seconds
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 5000); // Change to 30000 for 30 seconds

    // Clear the timer on cleanup
    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };
  {
    /*Popup scripts End */
  }
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: false, // Only animate once
      disable: () => window.innerWidth < 768, // Disable on mobile (screen width < 768px)
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const phoneNumber = "9177444288"; // Replace with the recipient's phone number in international format (no '+' or leading '00')
  const message = "Hello, I'm interested in your services!"; // Pre-filled message

  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message
  )}`;
  return (
    <div>
      {/* {showPopup && <OnloadPopup closePopup={closePopup} />} */}
      <div>
        <div className="Banner">
          <img src={banner} alt="" />
          <h1>Quartz</h1>
        </div>
        <div className="product-sec-1-wrap" data-aos="zoom-in-up">
          <div className="wrapper">
            <div className="divinelogo">
              <img src={divinelogo} alt="Section1" data-aos="zoom-in-up" />
            </div>
            <p>
              Divine Surfaces from Hyderabad, India, has been a leading exporter
              of quartz slabs and cut-to-size products from India, specializing
              in the manufacture of prefabricated slabs. In fact, we are India’s
              first and only quartz-engineered stone unit exclusively for
              cut-to-size. We continue to serve all our customers across India,
              North America, Europe, Australia, and UAE year after year and hope
              to continue going forward.
            </p>
          </div>

          <div className="wrapper">
            <div className="global-section-1-bg">
              <div className="wrapper">
                <div className="global-section-1-wrap">
                  <div className="global-section-1-1">
                    <img src={Section2} alt="Section12" data-aos="zoom-in-up" />
                  </div>
                  <div className="global-section-1-2" data-aos="fade-up-left">
                    <div className="bullet-2">
                      <ul>
                        <li>
                          Our state-of-the-art production facility in Hyderabad
                          is equipped with a fully automatic quartz slab
                          manufacturing line, a complete quartz slab calibration
                          and polishing line, 5 Axis CNC & 4 Axis Miter saws for
                          countertop, kitchen-top, and vanity making, 5 Axis
                          waterjet machines, and bridge cutters.
                        </li>
                        <li>
                          The manufactured product is subject to stringent
                          quality control at every stage of the process,
                          ensuring dimensional accuracy and perfect finish.
                        </li>
                        <li>
                          We specialize in delivering precision-engineered
                          quartz surfaces tailored to meet diverse global
                          requirements.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="gallery-2">
                {instagallery.map((src, i) => (
                  <img src={src} alt="" />
                ))}
              </div>
            </div>
            <br />
            <h2>For more details please</h2>
            <div className="connect-sec-wrap">
              <div className="connect-sec-1">
                <a
                  href="https://www.divinesurfaces.in"
                  target="_blank"
                  onClick={scrollToTop}
                >
                  <img src={icon4} alt="" />
                </a>
              </div>
              <div className="connect-sec-1">
                <a
                  href="mailto:info@divinesurfaces.in"
                  target="_blank"
                  onClick={scrollToTop}
                >
                  <img src={icon2} alt="" />
                </a>
              </div>
              <div className="connect-sec-1">
                <a
                  href={whatsappLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={icon3} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DivineSpacesGlobal;
