const QuartzcolourRangeListDataPage = {
    Argento: {
      name : "Argento",
      images: [
        {
          path : "FebruusBeige4020",
          imageName: "4020 Februus Beige",
          src: "/images/ColourRange/Argento/4020.png",
          alt: "4020 Februus Beige",
      
        },
        {
          path : "MonetaBeige4021",
          imageName: "4021 Moneta Beige",
          src: "/images/ColourRange/Argento/4021.png",
          alt: "4021 Moneta Beige",
        },
        {
          path : "BiamcoPepe5005",
          imageName: "5005 Bianco Pepe",
          src: "/images/ColourRange/Argento/5005.png",
          alt: "5005 Bianco Pepe",
      
        },
        {
          path : "AradiaBeige5008",
          imageName: "5008 Aradia Beige",
          src: "/images/ColourRange/Argento/5008.png",
          alt: "5008 Aradia Beige",
        },
        {
          path : "GrigioCristallo6015",
          imageName: "6015 Grigio Cristallo",
          src: "/images/ColourRange/Argento/6015.png",
          alt: "6015 Grigio Cristallo",
      
        },
        {
          path : "GrigioCristallo6016",
          imageName: "6016 Grigio Cristallo",
          src: "/images/ColourRange/Argento/6016.png",
          alt: "6016 Grigio Cristallo",
        },
        {
          path : "AradiaGrigio6025",
          imageName: "6025 Aradia Grigio",
          src: "/images/ColourRange/Argento/6025.png",
          alt: "6025 Aradia Grigio",
        },
        {
          path: "GrigioArmani6029",
          imageName: "6029 Grigio Armani",
          src: "/images/ColourRange/Argento/6029.png",
          alt: "6029 Grigio Armani",
        },
      ],
    },
    //.2
    Bronzo: {
      name : "Bronzo",
      images: [
        {
           path : "CiottoloBeige5004",
          imageName: "5004 Ciottolo Beige",
          src : "/images/ColourRange/Bronzo/bronzo-5004.jpg",
          // src: "images/ColourRange/Bronzo/bronzo-5004.png",
          alt: "5004 Ciottolo Beige",
        },
        {
          path: "Cemento6027",
          imageName : "6027 Cemento",
          src: "/images/ColourRange/Bronzo/bronzo-6027.jpg",
          alt: "6027 Cemento",
          // description: "This is Item B description",
        },
        
      ],
    },
    //.3
    Cobalto: {
      name : "Cobalto",
        images: [
          {
            path : "Nerissimo1050",
            imageName: "1050 Nerissimo",
            src: "/images/ColourRange/Cobalto/1050.png",
            alt: "1050 Nerissimo",
          },
          {
            path : "AradiaNero1051",
            imageName: "1051 Aradia Nero",
            src: "/images/ColourRange/Cobalto/1051.png",
            alt: "1051 Aradia Nero",
          },
          {
            path : "BlackVein1052",
            imageName: "1052 Black Vein",
            src: "/images/ColourRange/Cobalto/1052.png",
            alt: " 1052 Black Vein",
          },
          {
            path : "SummerStorm3031",
            imageName: "3031 Summer Storm",
            src: "/images/ColourRange/Cobalto/3031.png",
            alt: "3031 Summer Storm",
          },
           {
            path : "SaharaDream3032",
            imageName: "3032 Sahara Dream",
            src: "/images/ColourRange/Cobalto/3032.png",
            alt: "3032 Sahara Dream",
          },
          {
            path : "CloudyWhite3033",
            imageName: "3033 Cloudy White",
            src: "/images/ColourRange/Cobalto/3033.png",
            alt: "3033 Cloudy White",
          },
          {
            path : "CremaVenice3034",
            imageName: "3034 Crema Venice",
            src: "/images/ColourRange/Cobalto/3034.png",
            alt: "3034 Crema Venice",
          },
          {
            path : "Raindrops3035",
            imageName: "3035 Raindrops",
            src: "/images/ColourRange/Cobalto/3035.png",
            alt: "3035 Raindrops",
          },
          {
            path : "RomaGrigio6012",
            imageName: "6012 Roma Grigio",
            src: "/images/ColourRange/Cobalto/6012.png",
            alt: "6012 Roma Grigio",
          },
          {
            path : "BiancoPuro9018",
            imageName: "9018 Bianco Puro",
            src: "/images/ColourRange/Cobalto/9018.png",
            alt: "9018 Bianco Puro",
          },
        ],
    },
    //.4
    Oro: {
      name : "Oro",
      images: [
        {
           path : "BiancoAcero9020",
          imageName: "9020 Bianco Acero",
          src : "/images/ColourRange/Oro/9020.png",
          // src: "images/ColourRange/Bronzo/bronzo-5004.png",
          alt: "9020 Bianco Acero",
        },
        {
          path: "AradiaBianca4014",
          imageName : "4014 Aradia Bianca",
          src: "/images/ColourRange/Oro/4014.png",
          alt: "4014 Aradia Bianca",
          // description: "This is Item B description",
        },
        {
          path: "Cappuccino4019",
          imageName : "4019 Cappuccino",
          src: "/images/ColourRange/Oro/4019.png",
          alt: "4019 Cappuccino",
        },
        {
          path: "AradiaChocolati4022",
          imageName : "4022 Aradia Chocolati",
          src: "/images/ColourRange/Oro/4022.png",
          alt: "4022 Aradia Chocolati",
        },
        {
          path: "CeresBeige5009",
          imageName : "5009 Ceres Beige",
          src: "/images/ColourRange/Oro/5009.png",
          alt: "5009 Ceres Beige",
        },
        {
          path: "GrigioPuro6023",
          imageName : "6023 Grigio Puro",
          src: "/images/ColourRange/Oro/6023.png",
          alt: "6023 Grigio Puro",
        },
        {
          path: "GrigioJapetus6024",
          imageName : "6024 Grigio Japetus",
          src: "/images/ColourRange/Oro/6024.png",
          alt: "6024 Grigio Japetus",
        },
        {
          path: "AradiaLivid6026",
          imageName : "6026 Aradia Livid",
          src: "/images/ColourRange/Oro/6026.png",
          alt: "6026 Aradia Livid",
        },
        {
          path: "GrigioChiaro6028",
          imageName : "6028 Grigio Chiaro",
          src: "/images/ColourRange/Oro/6028.png",
          alt: "6028 Grigio Chiaro",
        },
        {
          path: "Concerto6030",
          imageName : "6030 Concerto",
          src: "/images/ColourRange/Oro/6030.png",
          alt: "6030 Concerto",
        },
        {
          path: "BiancoFortuna9019",
          imageName : "9019 Bianco Fortuna",
          src: "/images/ColourRange/Oro/9019.png",
          alt: "9019 Bianco Fortuna",
        },
        {
          path: "BiancoCristallo9022",
          imageName : "9022 Bianco Cristallo",
          src: "/images/ColourRange/Oro/9022.png",
          alt: "9022 Bianco Cristallo",
        },
        {
          path: "MiscellaArruzzo9024",
          imageName : "9024 Miscella Arruzzo",
          src: "/images/ColourRange/Oro/9024.png",
          alt: "9024 Miscella Arruzzo",
        },
      ]
    },
    //.5
    Palladio: {
      name : "Palladio",
      images: [
        {
           path : "CalacattaSottile8021",
          imageName: "8021 Calacatta Sottile",
          src : "/images/ColourRange/Palladio/8021.jpg",
          // src: "images/ColourRange/Bronzo/bronzo-5004.png",
          alt: "8021 Calacatta Sottile",
        },
        {
          path: "CalacattaEsotico8022",
          imageName : "8022 Calacatta Esotico",
          src: "/images/ColourRange/Palladio/8022.jpg",
          alt: "8022 Calacatta Esotico",
          // description: "This is Item B description",
        },
        {
          path: "CalacattaAshNova8023",
          imageName : "8023 Calacatta Ash Nova",
          src: "/images/ColourRange/Palladio/8023.jpg",
          alt: "8023 Calacatta Ash Nova",
          // description: "This is Item B description",
        },
        {
          path: "CalacattaAshen8024",
          imageName : "8024 Calacatta Ashen",
          src: "/images/ColourRange/Palladio/8024.jpg",
          alt: "8024 Calacatta Ashen",
          // description: "This is Item B description",
        },
        {
          path: "CalacattaDusky8025",
          imageName : "8025 Calacatta Dusky",
          src: "/images/ColourRange/Palladio/8025.jpg",
          alt: "8025 Calacatta Dusky",
          // description: "This is Item B description",
        },
        {
          path: "CalacattaSmoky8026",
          imageName : "8026 Calacatta Smoky",
          src: "/images/ColourRange/Palladio/8026.jpg",
          alt: "8026 Calacatta Smoky",
          // description: "This is Item B description",
        },

      ]
    },
      //.6
    Platino: {
      name : "Platino",
      images: [
        {
           path : "CarraraOroe8019B",
          imageName: "8019B Carrara Oroe",
          src : "/images/ColourRange/Platino/8020-Carrara-Linea.jpg",
          // src: "images/ColourRange/Bronzo/bronzo-5004.png",
          alt: "8019B Carrara Oro",
        },
        {
          path: "CarraraLinea8020",
          imageName : "8020 Carrara Linea",
          src: "/images/ColourRange/Platino/8019B-Carrara-Oro.jpg",
          alt: "8020 Carrara Linea",
          // description: "This is Item B description",
        },
        {
          path: "PiatraGrizzle6031",
          imageName : "6031 Piatra Grizzle",
          src: "/images/ColourRange/Platino/6031-Piatra-Grizzle.jpg",
          alt: "6031 Piatra Grizzle",
          // description: "This is Item B description",
        },
      ]
    },
  };
  
  export default QuartzcolourRangeListDataPage;
  