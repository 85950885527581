// CategoriesPage.js
import React from "react";

import banner from "./images/Quartz-banner.png";

import rb1 from "./images/Drawings-Color.png";
import rb2 from "./images/Quotation.png";
import rb3 from "./images/Approval.png";
import rb4 from "./images/Confirmation.png";
import rb5 from "./images/Production.png";
import rb6 from "./images/Fabrication.png";
import rb7 from "./images/Quality-Check-Packing.png";
import rb8 from "./images/Loading-Dispatch.png";
// categories.js

const OurProcess = () => (
  <div>
    <div className="Banner">
      <img src={banner} alt="" />
      <h1>Our Process</h1>
    </div>

    <div className="Rostre-Benefits-bg">
      <div className="wrapper">
        <div className="n-Our-Process-wrap">
          <div className="n-Our-Process-1">
            <div className="n-Our-Process-1-1">
              <img src={rb1} alt="rb1" />
            </div>
            <div className="n-Our-Process-1-2">
              <h4>Drawings & Color</h4>
              <p>
                Send us draft shop drawings, along with pictures of color
                approved by builder/architect
              </p>
            </div>
          </div>

          <div className="n-Our-Process-1">
            <div className="n-Our-Process-1-1">
              <img src={rb2} alt="rb1" />
            </div>
            <div className="n-Our-Process-1-2">
              <h4>Quotation</h4>
              <p>
                We will submit quotation within 48 working hours of receipt of
                drawings & color details
              </p>
            </div>
          </div>

          <div className="n-Our-Process-1">
            <div className="n-Our-Process-1-1">
              <img src={rb3} alt="rb1" />
            </div>
            <div className="n-Our-Process-1-2">
              <h4>Approval</h4>
              <p>
                You approve quotation sent by us as well as courier us a sample
                of color approved by builder/architect for matching. Incase
                final drawings are required from our end the same can be
                prepared and sent for signature
              </p>
            </div>
          </div>
          <div className="n-Our-Process-1">
            <div className="n-Our-Process-1-1">
              <img src={rb4} alt="rb1" />
            </div>
            <div className="n-Our-Process-1-2">
              <h4>Confirmation</h4>
              <p>
                We send final approved drawings as well as courier lab matched
                sample for buyers approval
              </p>
            </div>
          </div>
          <div className="n-Our-Process-1">
            <div className="n-Our-Process-1-1">
              <img src={rb5} alt="rb1" />
            </div>
            <div className="n-Our-Process-1-2">
              <h4>Production</h4>
              <p>
                We will submit quotation within 48 working hours of receipt of
                drawings & color details
              </p>
            </div>
          </div>
          <div className="n-Our-Process-1">
            <div className="n-Our-Process-1-1">
              <img src={rb6} alt="rb1" />
            </div>
            <div className="n-Our-Process-1-2">
              <h4>Fabrication</h4>
              <p>
                Our team will prepare the cutting sheets, load them on the
                bridge saws and waterjet machines and ensure precise execution
              </p>
            </div>
          </div>
          <div className="n-Our-Process-1">
            <div className="n-Our-Process-1-1">
              <img src={rb7} alt="rb1" />
            </div>
            <div className="n-Our-Process-1-2">
              <h4>Quality Check & Packing</h4>
              <p>
                We check each piece for size, color, thickness, surface finish
                before it is packed. Packing is done using Australian pinewood
                as per instructions received from the buyer. All pieces as well
                as boxes are labelled to ensure easy installation
              </p>
            </div>
          </div>
          <div className="n-Our-Process-1">
            <div className="n-Our-Process-1-1">
              <img src={rb8} alt="rb1" />
            </div>
            <div className="n-Our-Process-1-2">
              <h4>Loading & Dispatch</h4>
              <p>
                All boxes are loaded with adequate support to avoid any transit
                damage and photos are shared with buyer immediately after
                dispatch
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default OurProcess;
