import React from "react";
import { Link } from "react-router-dom";
import banner from "./images/Projects-banner.png";

import blogthumb1 from "./images/blogthumb1.png";
import blogthumb2 from "./images/blogthumb2.png";
import blogthumb3 from "./images/blogthumb3.png";
const blogs = [
  {
    id: 1,
    title: "Modern Cabinets",
    subtitle: "Lorem Ipsum",
    image: blogthumb1,
    description:
      "Discover the elegance and functionality of modern cabinets for every space.",
  },
  {
    id: 2,
    title: "Transform  Space",
    subtitle: "Style Meets Simplicity",
    image: blogthumb2,
    description:
      "Explore how minimalist kitchen designs can transform your home.",
  },
  {
    id: 3,
    title: "Elevating Your Kitchen",
    subtitle: "Efficient Spaces",
    image: blogthumb3,
    description: "Learn about innovative storage solutions for modern living.",
  },

  {
    id: 5,
    title: "Transform  Space",
    subtitle: "Style Meets Simplicity",
    image: blogthumb2,
    description:
      "Explore how minimalist kitchen designs can transform your home.",
  },
  {
    id: 4,
    title: "Modern Cabinets",
    subtitle: "Lorem Ipsum",
    image: blogthumb1,
    description:
      "Discover the elegance and functionality of modern cabinets for every space.",
  },
  {
    id: 6,
    title: "Elevating Your Kitchen",
    subtitle: "Efficient Spaces",
    image: blogthumb3,
    description: "Learn about innovative storage solutions for modern living.",
  },
];

function Contactus() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div>
        <div className="Banner">
          <img src={banner} alt="" />
          <h1>Projects</h1>
        </div>
        <div className="contact-bg">
          <div className="wrapper">
            <div className="Projects-wrap">
              <div className="Projects-1">
                <h2>Project Gallery</h2>
                <div className="Projects-sec">
                  {blogs.map((blog) => (
                    <div key={blog.id} className="Projects-item">
                      <img src={blog.image} alt={blog.title} />
                      <div className="Projects-content">
                        <h6>{blog.title}</h6>
                        <p>{blog.subtitle}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="Projects-2">
                <h2>Get a Quote</h2>
                <input
                  type="text"
                  placeholder="First Name*"
                  className="text-filed-1"
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  className="text-filed-1"
                />
                <input
                  type="text"
                  placeholder="Phone Number*"
                  className="text-filed-1"
                />
                <input
                  type="text"
                  placeholder="Email"
                  className="text-filed-1"
                />
                <textarea
                  placeholder="Message"
                  className="text-area-1"
                ></textarea>
                <button className="button-4">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contactus;
