// CategoriesPage.js
import React, { useState, useEffect } from "react";
import OnloadPopup from "./OnloadPopup";
import { isMobile } from "react-device-detect";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

import banner from "./images/Quartz-banner.png";
import Process from "./images/Process-1.png";
import ColourRange from "./images/Colour-Range-1.png";
import EdgeProfiles from "./images/Edge-Profiles-1.png";
import Products from "./images/Products-1.png";

AOS.init({
  disable: isMobile,
});
// categories.js
const scrollToTop = () => {
  window.scrollTo(0, 0);
};
const categories = {
  ProductCategories: {
    name: "",
    description:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour",
    items: [
      {
        id: 1,
        name: "Colour Range",
        thumbnail: "./images/Colour-Range.png",
        categoryPath: "ColourRange",
      },
      {
        id: 2,
        name: "Products",
        thumbnail: "./images/Products.png",
      },
      {
        id: 3,
        name: "Finishes",
        thumbnail: "./images/Finishers.png",
      },
    ],
  },
};
function Quartz() {
  {
    /*Popup scripts start */
  }
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Show the popup after 30 seconds
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 5000); // Change to 30000 for 30 seconds

    // Clear the timer on cleanup
    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };
  {
    /*Popup scripts End */
  }

  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: false, // Only animate once
      disable: () => window.innerWidth < 768, // Disable on mobile (screen width < 768px)
    });
  }, []);

  return (
    <>
      <div>
        {/* {showPopup && <OnloadPopup closePopup={closePopup} />} */}
        <div className="Banner">
          <img src={banner} alt="" />
          <h1>Quartz</h1>
        </div>
        <div className="product-sec-1-wrap">
          <div className="wrapper">
            <p data-aos="zoom-in-up">
              Our quartz collection showcases the pinnacle of engineered stone,
              offering a harmonious blend of durability and stunning visual
              appeal. Our group company Divine Surfaces is India’s first and
              only quartz unit to make slabs specially for countertops and other
              prefabricated products. This material offers virtually zero
              porosity, effectively preventing the absorption of water or other
              liquids. Its exceptional durability ensures resistance to
              abrasion, scratching, and wear, maintaining surface integrity over
              time. With remarkable acid resistance, it can withstand exposure
              to acidic foods like lemon, tomato, and wine without staining or
              damage. High flexural strength provides excellent impact
              resistance and structural resilience, while effortless maintenance
              allows for easy cleaning and minimal upkeep. Additionally, the
              naturally non-porous surface inhibits bacterial growth, making it
              food-safe and ideal for kitchens without requiring chemical
              treatments. For commercial spaces, its a low-maintenance resilient
              option too.
            </p>
          </div>
        </div>
        <div className="product-list-wrap-2">
          {Object.keys(categories).map((category) => (
            <div key={category}>
              <div className="wrapper">
                <div className="Quartz-images">
                  <Link
                    to="/QuartzProducts"
                    onClick={scrollToTop}
                    data-aos="zoom-in-up"
                  >
                    <img src={Products} alt="Products" />
                    <div className="catname">Products</div>
                  </Link>
                  {/* <Link
                    to="/EdgeProfiles"
                    onClick={scrollToTop}
                    data-aos="zoom-in-up"
                  >
                    <img src={EdgeProfiles} alt="Products" />
                    <div className="catname">Edge Profiles</div>
                  </Link> */}
                  {/* <Link
                    to="/ColourRange"
                    onClick={scrollToTop}
                    data-aos="zoom-in-up"
                  > */}
                  <Link
                    to={`/Quartz/${categories.ProductCategories.items[0].categoryPath}`}
                  >
                    <img src={ColourRange} alt="Products" />
                    <div className="catname">Colour Range</div>
                  </Link>
                  <Link
                    to="/OurProcess"
                    onClick={scrollToTop}
                    data-aos="zoom-in-up"
                  >
                    <img src={Process} alt="Products" />
                    <div className="catname">Process</div>
                  </Link>
                </div>
                <div className="text-center">
                  <Link
                    to="https://www.divinesurfaces.in/"
                    className="button-3"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Divine Surfaces Website{" "}
                  </Link>
                </div>
                <br />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Quartz;
