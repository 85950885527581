// CategoriesPage.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import AOS from "aos";
import "aos/dist/aos.css";
import banner from "./images/Granite-banner-1.png";
import GraniteColourSlider from "./GraniteColourSlider";
import GraniteFinishesSlider from "./GraniteFinishesSlider";
import OnloadPopup from "./OnloadPopup";
AOS.init({
  disable: isMobile,
});

// categories.js

// categories.js
const scrollToTop = () => {
  window.scrollTo(0, 0);
};
const categories = {
  ProductCategories: {
    name: "",
    description:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour",
    items: [
      {
        id: 1,
        name: "Colours",
        thumbnail: "./images/Colour-Range.png",
      },
      {
        id: 2,
        name: "Products",
        thumbnail: "./images/Products.png",
      },
      {
        id: 3,
        name: "Finishes",
        thumbnail: "./images/Finishers.png",
      },
    ],
  },
};

function Granite() {
  {
    /*Popup scripts start */
  }
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Show the popup after 30 seconds
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 5000); // Change to 30000 for 30 seconds

    // Clear the timer on cleanup
    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };
  {
    /*Popup scripts End */
  }
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: false, // Only animate once
      disable: () => window.innerWidth < 768, // Disable on mobile (screen width < 768px)
    });
  }, []);
  return (
    <>
      {/* {showPopup && <OnloadPopup closePopup={closePopup} />} */}
      <div>
        <div className="Banner">
          <img src={banner} alt="" />
          <h1>Granite</h1>
        </div>
        <div className="product-sec-1-wrap">
          <div className="wrapper">
            <p data-aos="zoom-in-up">
              Our granite collection celebrates the artistry of nature. Our
              group company Granite Mart has been a pioneer in the industry
              since 30 years. We boast of having a fully owned state of the art
              manufacturing facility with a multi wire saw, Italian polishing
              machines and fully automated CNC machines to make countertops and
              perfect edges. Each slab is selected for its distinctive beauty
              and exceptional durability, making it a perfect choice for various
              applications—from elegant countertops to resilient flooring.
            </p>
            <p data-aos="zoom-in-up">
              Our granite is available in a rich array of colors, patterns and
              textures and allows for timeless sophistication in any space. The
              cut-to-size products are made on fully automated CNC machines to
              ensure precision. We have several options for edge profiles.
            </p>
            <p data-aos="zoom-in-up">
              We take pride in our meticulous sourcing and finishing processes,
              ensuring that each piece meets rigorous quality and aesthetic
              standards. Our extensive network of quarry partnerships across the
              country ensures access to granite in a wide range of colors and
              premium quality. Above all, we prioritize customer satisfaction,
              always delivering on our quality commitments
            </p>
          </div>
        </div>
        {/* <div className="product-list-wrap-2">
          {Object.keys(categories).map((category) => (
            <div key={category} className="product-list-bg">
              <div className="wrapper">
                <h2>{category}</h2>
                <p>{categories[category].description}</p>

                <div className="category-images">
                  {categories[category].items.map((item) => (
                    <Link
                      key={item.id}
                      to={`/Granite/${category}/${item.id}`}
                      onClick={scrollToTop}
                    >
                      <img src={item.thumbnail} alt={item.name} />
                      <div className="catname">{item.name}</div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div> */}

        <div className="Supplier-Collaborations gray-bg-2">
          <div className="wrapper">
            <h3 className="line-small-white">Colours</h3>
            <p>
              We offer a wide range of colours sourced from quarries all over
              the country. They are available in Polished, Flamed, Honed and
              Leather finishes.
            </p>
            <GraniteColourSlider />
          </div>
        </div>

        <div className="Supplier-Collaborations ">
          <div className="wrapper">
            <h3 className="line-small">Finishes</h3>
            <p>
              Select from various finishes—polished, honed, or textured—to
              achieve the desired look and feel for your countertops and
              vanities.
            </p>
            <GraniteFinishesSlider />
          </div>
        </div>
      </div>
    </>
  );
}

export default Granite;
