import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import larrow from "./images/left-arrow-1.png";
import rarrow from "./images/right-arrow-1.png";

function TestimonialsSlider() {
  const sliderRef = useRef(null); // Reference for the slider
  const [activeSlide, setActiveSlide] = useState(0);

  const testimonials = [
    {
      text: `"What was different about Rostre was the complete transparency in the design and quotation process. I was invited to the factory to see the process and choose my options. The installation process was seamless and the quality is exceptional. I was honestly amazed!"`,
      author: "Rahul Singhvi - Happy client in Hyderabad",
    },
    {
      text: `"I thank the Rostre team for making my home look so beautiful and functional at the same time."`,
      author: "Roshni, Hyderabad",
    },
    {
      text: `"I loved the fact that the Rostre team was so professional during the design and quotation process and helped us make informed decisions. The finished product that arrived home was even better. I would blindly recommend them to anyone."`,
      author: "Abhishek Goel, Hyderabad",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    draggable: true,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true, // Disable default arrows
    beforeChange: (current, next) => setActiveSlide(next),
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div className="slider-home testimonials-sec" id="testimonialsslider">
      <Slider ref={sliderRef} {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonials-wrap">
            <p>{testimonial.text}</p>
            <p>
              <i>{testimonial.author}</i>
            </p>
          </div>
        ))}
      </Slider>
      <div className="slider-buttons">
        <img
          src={larrow}
          onClick={handlePrev}
          className="prev-button"
          alt="Previous"
        />
        <img
          src={rarrow}
          onClick={handleNext}
          className="next-button"
          alt="Next"
        />
      </div>
    </div>
  );
}

export default TestimonialsSlider;
