import React, { useState, useEffect } from "react";
import OnloadPopup from "./OnloadPopup";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import banner from "./images/RostreCabinetsGlobalbanner.png";
import divinelogo from "./images/gloval-logo-3.png";
import Section2 from "./images/RostreCabinetsGlobal-3.png";
import Section4 from "./images/RostreCabinetsGlobal-3a.png";

import icon1 from "./images/call-1-1.png";
import icon2 from "./images/email-1-1.png";
import icon3 from "./images/whatsup-1-1.png";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  disable: isMobile,
});

const instagallery = [
  require("./images/RostreCabinetsGlobal-1.png"),
  require("./images/RostreCabinetsGlobal-2.png"),
];

function RostreCabinetsGlobal() {
  {
    /*Popup scripts start */
  }
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Show the popup after 30 seconds
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 5000); // Change to 30000 for 30 seconds

    // Clear the timer on cleanup
    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };
  {
    /*Popup scripts End */
  }
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: false, // Only animate once
      disable: () => window.innerWidth < 768, // Disable on mobile (screen width < 768px)
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const phoneNumber = "9177444288"; // Replace with the recipient's phone number in international format (no '+' or leading '00')
  const message = "Hello, I'm interested in your services!"; // Pre-filled message

  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message
  )}`;
  return (
    <div>
      {/* {showPopup && <OnloadPopup closePopup={closePopup} />} */}
      <div>
        <div className="Banner">
          <img src={banner} alt="" />
          <h1>Cabinets</h1>
        </div>
        <div className="product-sec-1-wrap" data-aos="zoom-in-up">
          <div className="wrapper">
            <div className="divinelogo">
              <img src={divinelogo} alt="Section1" data-aos="zoom-in-up" />
            </div>
            <p>
              Rostre Cabinet manufactures modular kitchens and cabinets,
              wardrobes, bathroom vanities and consoles in the latest finishes
              on high precision machinery from Germany. We are supplying these
              for building projects, multi family homes and to distributors for
              resale. There is a wide choice of frameless finishes for shutters
              and surfaces to choose from like
            </p>
          </div>

          <div className="wrapper">
            <div className="global-section-1-bg">
              <div className="wrapper">
                <div className="global-section-1-wrap">
                  <div className="global-section-1-1">
                    <img src={Section2} alt="Section12" data-aos="zoom-in-up" />
                  </div>
                  <div className="global-section-1-2" data-aos="fade-up-left">
                    <div className="bullet-2  bullet-2b">
                      <ul>
                        <li>Thermofoil</li>
                        <li>Acrylic</li>
                        <li>Paint</li>
                        <li>Laminate</li>
                        <li>Glass</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="global-section-1-wrap">
                  <div className="global-section-1-2" data-aos="fade-up-left">
                    <p className="text-left-1">
                      Some of the aspects that are unique to our company are :
                    </p>
                    <div className="bullet-2  bullet-2b">
                      <ul>
                        <li>Quick turnaround time</li>
                        <li>
                          Only pinewood used for packing for shipping to ensure
                          no moulds and fungus
                        </li>
                        <li>Exemplary customer service</li>
                        <li>Competitive pricing</li>
                        <li>Excellent quality and attention to detail</li>
                      </ul>
                    </div>
                  </div>

                  <div className="global-section-1-1">
                    <img src={Section4} alt="Section12" data-aos="zoom-in-up" />
                  </div>
                </div>
                <br />
                <p>
                  We strive to use the best European technology for our
                  manufacturing processes, leading to a fully automated
                  manufacturing plant. We use the best brands of hardware and
                  give you a wide variety of options of finishes to choose from.
                </p>
                <br />
              </div>
            </div>
            <div className="wrapper">
              <div className="gallery-2">
                {instagallery.map((src, i) => (
                  <img src={src} alt="" />
                ))}
              </div>
            </div>
            <br />
            <h2>For more details please</h2>
            <div className="connect-sec-wrap">
              <div className="connect-sec-1">
                <Link to="/Contactus" onClick={scrollToTop}>
                  <img src={icon1} alt="" />
                </Link>
              </div>
              <div className="connect-sec-1">
                <a
                  href="mailto:info@rostre.in"
                  target="_blank"
                  onClick={scrollToTop}
                >
                  <img src={icon2} alt="" />
                </a>
              </div>
              <div className="connect-sec-1">
                <a
                  href={whatsappLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={icon3} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RostreCabinetsGlobal;
