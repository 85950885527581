import React from "react";
import { Link, useParams } from "react-router-dom";
import banner from "./images/Blog-banner.png";
const blogs = [
  {
    id: 1,
    title: "Modern Cabinets ",
    subtitle: "Lorem Ipsum",
    image: "./images/blogthumb1.png",
    bannerImage: "../images/blogbanner1.png",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
  },
  {
    id: 2,
    title: "Transform Your Space",
    subtitle: "Style Meets Simplicity",
    image: "./images/blogthumb1.png",
    bannerImage: "../images/blogbanner1.png",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
  },
  {
    id: 3,
    title: "Elevating Your Kitchen",
    subtitle: "Efficient Spaces",
    image: "./images/blogthumb1.png",
    bannerImage: "../images/blogbanner1.png",
    description: "Learn about innovative storage solutions for modern living.",
  },
  {
    id: 4,
    title: "The Art of Customized Cabinets",
    subtitle: "Efficient Spaces",
    image: "./images/blogthumb1.png",
    bannerImage: "../images/blogbanner1.png",
    description: "Learn about innovative storage solutions for modern living.",
  },
  {
    id: 5,
    title: "Functional Cabinetry",
    subtitle: "Efficient Spaces",
    image: "./images/blogthumb1.png",
    bannerImage: "../images/blogbanner1.png",
    description: "Learn about innovative storage solutions for modern living.",
  },
  {
    id: 6,
    title: "Trends in Cabinet",
    subtitle: "Efficient Spaces",
    image: "./images/blogthumb1.png",
    bannerImage: "../images/blogbanner1.png",
    description: "Learn about innovative storage solutions for modern living.",
  },
];

const BlogDetailPage = () => {
  const { id } = useParams();
  const blog = blogs.find((blog) => blog.id === parseInt(id));

  if (!blog) {
    return <div>Blog not found</div>;
  }

  return (
    <>
      <div className="Banner">
        <img src={banner} alt="" />
        <h1>Blog</h1>
      </div>

      <div className="wrapper blog-inner">
        {/* Banner Image */}
        <div className="banner">
          <img
            src={blog.bannerImage}
            alt={`${blog.title} Banner`}
            className="banner-image"
          />
        </div>

        {/* Blog Content */}
        <div className="content">
          <h1>{blog.title}</h1>
          <h3>{blog.subtitle}</h3>
          <p>{blog.description}</p>

          <Link to="/" className="backtoblog">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <path
                d="M20.2376 13.0719L7.98975 13.2992L13.3363 18.4509L12.6903 19.213L6.0708 12.8347L12.4491 6.21525L13.1229 6.95288L7.9712 12.2994L20.2191 12.0721L20.2376 13.0719Z"
                fill="#B09955"
              />
            </svg>
            Back to Blog Page
          </Link>
        </div>
      </div>
    </>
  );
};

export default BlogDetailPage;
