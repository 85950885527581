import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import QuartzDataPage from "./QuartzDataPage"; // Importing Quartz data
import banner from "./images/Quartz-banner.png";

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const QuartzInsidePage = () => {
  const { categoryName } = useParams(); // Get category from URL params
  const category = QuartzDataPage[categoryName]; // Get matching category details

  if (!category) {
    return <p>Category not found</p>; // Handle invalid category
  }

  return (
    <div>
      <div className="Banner">
        <img src={banner} alt="Quartz Banner" />
        <h1>Colour Range</h1>
      </div>
      <div className="product-sec-1-wrap">
        <div className="wrapper">
          <p>
            We at Divine, produce premium quality slabs, which are made from
            natural quartz. We have a fully automatic pressing line with mould
            technology. This enables us to reduce manufacturing process defects.
            All our slabs are then calibrated on both sides followed by
            polishing on a fully automatic machine using Italian abrasives. We
            provide even custom colours as per your requirement, which are
            formulated in our state-of-the-art lab.
          </p>
        </div>
      </div>

      <div className="product-list-wrap-2">
        <div className="product-list-bg">
          <div className="wrapper">
            <div className="category-images">
              {category.images.map((image, index) => (
                <Link
                  key={index}
                  to={`/Quartz/${categoryName}/${image.imageName}`} // Construct URL dynamically
                >
                  <img src={image.src} alt={image.alt} onClick={scrollToTop} />
                  <div className="catname" onClick={scrollToTop}>
                    {image.imageName}
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuartzInsidePage;
