// insidePageData.js

const QuartzProductsinsidePageData = {
  ProductCategories: [
    {
      id: 1,
      cat: "Quartz",
      heading: "Islands",
      subheading: "Product Details",
      subheading2:
        "Can be done in all colors we carry as seen in our slab section and we can even make custom colors for large projects .",
      description:
        "Islands are finished on all four sides. They may or may not have a sink cutout . Sizes for islands are customised to your needs. Average size of a kitchen island is 80 inches in length and 40 inches in depth but can vary..",
      banner: "/images/Quartz-banner.png",
      images: Array.from({ length: 1 }, (_, i) => ({
        //src: `/images/kitchen/img-${i + 1}.png`, // Correct path from the public folder
        src: `/images/Islands-thumb.png`,
        alt: `Islands - Image ${i + 1}`,
      })),
    },
    {
      id: 2,
      cat: "Quartz",
      heading: "Backsplash / Sidesplash",
      subheading: "Product Details",
      subheading2:
        "Can be done in all colors we carry as seen in our slab section and we can even make custom colors for large projects .",
      description: "Backsplash / Sidesplash",
      banner: "/images/Quartz-banner.png",
      images: Array.from({ length: 1 }, (_, i) => ({
        //src: `/images/kitchen/img-${i + 1}.png`, // Correct path from the public folder
        src: `/images/Backsplash-thumb.png`,
        alt: `Backsplash / Sidesplash - Image ${i + 1}`,
      })),
    },

    {
      id: 3,
      cat: "Quartz",
      heading: "Vanity tops",
      subheading: "Product Details",
      subheading2:
        "Can be done in all colors we carry as seen in our slab section and we can even make custom colors for large projects .",
      description:
        "Vanities are usually finished on two sides with backsplash on the other two. Depending on the length, they can have one or two sink cutouts. Standard length of vanities are 24, 30, 36, 48, 60 and 72 inches and the depth is 20-22 inches.",
      banner: "/images/Quartz-banner.png",
      images: Array.from({ length: 1 }, (_, i) => ({
        //src: `/images/kitchen/img-${i + 1}.png`, // Correct path from the public folder
        src: `/images/Vanity-tops-thumb.png`,
        alt: `Vanity tops - Image ${i + 1}`,
      })),
    },
    {
      id: 4,
      cat: "Quartz",
      heading: "Countertops",
      subheading: "Product Details",
      subheading2: "",
      description:
        "Countertops are usually finished on one long and one short side and may or may not have a sink cutout. Sizes for countertops are customised to your needs. Length is based on kitchen size and usual depth is 25.5 inches but can vary.",
      banner: "/images/Quartz-banner.png",
      images: Array.from({ length: 1 }, (_, i) => ({
        //src: `/images/kitchen/img-${i + 1}.png`, // Correct path from the public folder
        src: `/images/Countertops-thumb.png`,
        alt: `Vanity tops - Image ${i + 1}`,
      })),
    },
    {
      id: 5,
      cat: "Quartz",
      heading: "Window Sills",
      subheading: "Product Details",
      subheading2:
        "Can be done in all colors we carry as seen in our slab section and we can even make custom colors for large projects .",
      description: "Window Sills",
      banner: "/images/Quartz-banner.png",
      images: Array.from({ length: 1 }, (_, i) => ({
        //src: `/images/kitchen/img-${i + 1}.png`, // Correct path from the public folder
        src: `/images/Window-Sills-thumb.png`,
        alt: `Vanity tops - Image ${i + 1}`,
      })),
    },
    {
      id: 6,
      cat: "Quartz",
      heading: "Table tops",
      subheading: "Product Details",
      subheading2:
        "Can be done in all colors we carry as seen in our slab section and we can even make custom colors for large projects .",
      description:
        "Being such a versatile material quartz can be used for various tabletops across homes, offices, schools and clinics. It is easy to maintain and can be customised in a wide range of colours, shapes, sizes and edges.",
      banner: "/images/Quartz-banner.png",
      images: Array.from({ length: 1 }, (_, i) => ({
        //src: `/images/kitchen/img-${i + 1}.png`, // Correct path from the public folder
        src: `/images/Table-tops-thumb.png`,
        alt: `Vanity tops - Image ${i + 1}`,
      })),
    },
    {
      id: 7,
      cat: "Quartz",
      heading: "Wall Claddings",
      subheading: "Product Details",
      subheading2:
        "Can be done in all colors we carry as seen in our slab section and we can even make custom colors for large projects .",
      description: "Wall Claddings",
      banner: "/images/Quartz-banner.png",
      images: Array.from({ length: 1 }, (_, i) => ({
        //src: `/images/kitchen/img-${i + 1}.png`, // Correct path from the public folder
        src: `/images/Wall-Claddings-thumb.png`,
        alt: `Vanity tops - Image ${i + 1}`,
      })),
    },
  ],
};

export default QuartzProductsinsidePageData;
