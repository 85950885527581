import React, { useState, useEffect } from "react";

import { NavLink, Link } from "react-router-dom";
import { FiAlignRight, FiXCircle, FiChevronDown } from "react-icons/fi";
import { isMobile } from "react-device-detect";
import "../Styles.css";
import "./header.css";

import logo from "../images/logo.svg";
import logopng from "../images/logopng.png";
import phone from "../images/R-call.png";
import location from "../images/R-Location.png";

//import logo from "../../img/logo.png";
const Header = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleListItemClick = () => {
    toggleVisibility();
  };

  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [isMenu, setisMenu] = useState(false);
  const [isResponsiveclose, setResponsiveclose] = useState(false);

  const toggleClass = () => {
    setisMenu(isMenu === false ? true : false);
    setResponsiveclose(isResponsiveclose === false ? true : false);
  };

  let boxClass = ["main-menu menu-right menuq1"];
  if (isMenu) {
    boxClass.push("menuq2");
  } else {
    boxClass.push("");
  }
  const [isMenuSubMenu, setMenuSubMenu] = useState(false);
  const toggleSubmenu = () => {
    setMenuSubMenu(isMenuSubMenu === false ? true : false);
  };
  let boxClassSubMenu = ["sub__menus"];
  if (isMenuSubMenu) {
    boxClassSubMenu.push("sub__menus__Active");
  } else {
    boxClassSubMenu.push("");
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="topstrip">
        <div className="wrapper">
          <div className="topstrip-wrap">
            <div className="topstrip-1">
              <div className="phone-sec">
                <Link className="header-btn-1" to="/Contactus">
                  <img src={location} alt="location" /> Visit Our Showroom
                </Link>
              </div>
            </div>
            <div className="topstrip-2">
              <div className="phone-sec">
                {/* <Link className="header-btn-1" to="/Contactus">
                  <img src={phone} alt="phone" /> +91 90000 14692
                </Link> */}
                <a className="header-btn-1" href="tel:9000014692">
                  <img src={phone} alt="phone" /> +91 90000 14692
                </a>
              </div>
            </div>
          </div>
          {/* <div className="phone-sec">
            <img src={phone} alt="phone" /> +91 90000 14692
          </div>
          <Link className="btn-1" to="/Contactus">
            Visit Our Showroom
          </Link> */}
        </div>
      </div>
      <div className={scrolled ? "navbar scrolled" : "navbar"}>
        <div className="wrapper">
          <div className="header-new-wrap">
            <div className="header-new-logo">
              <NavLink exact activeClassName="is-active" to="/">
                <img
                  src={logopng}
                  alt="Rostre"
                  className={scrolled ? "logo scrolled" : "logo"}
                />
              </NavLink>
            </div>
            <div className="header-new-menu">
              <nav className="main-nav ">
                {/* Responsive Menu Button */}
                {isResponsiveclose === true ? (
                  <>
                    <span
                      className="menubar__button"
                      style={{ display: "none" }}
                      onClick={toggleClass}
                    >
                      <FiXCircle />
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      className="menubar__button"
                      style={{ display: "none" }}
                      onClick={toggleClass}
                    >
                      <FiAlignRight />
                    </span>
                  </>
                )}
                <ul className={boxClass.join(" ")}>
                  <li className="menu-item" onClick={scrollToTop}>
                    <NavLink
                      exact
                      activeClassName="is-active"
                      onClick={toggleClass}
                      to={`/`}
                    >
                      Home
                    </NavLink>
                  </li>

                  <li className="menu-item" onClick={scrollToTop}>
                    <NavLink
                      exact
                      activeClassName="is-active"
                      onClick={toggleClass}
                      to={`/Aboutus`}
                    >
                      About Us
                    </NavLink>
                  </li>
                  <li
                    onClick={toggleSubmenu}
                    className="menu-item sub__menus__arrows"
                  >
                    <NavLink
                      onClick={toggleClass}
                      activeClassName="is-active"
                      to={`/ModularFurniture`}
                    >
                      Modular Furniture <FiChevronDown />
                    </NavLink>

                    <ul className={boxClassSubMenu.join(" ")}>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/ModularFurniture/ProductCategories/1`}
                        >
                          Kitchens
                        </NavLink>
                      </li>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/ModularFurniture/ProductCategories/2`}
                        >
                          Wardrobes
                        </NavLink>
                      </li>

                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/ModularFurniture/ProductCategories/3`}
                        >
                          Bathroom Vanities
                        </NavLink>
                      </li>

                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/ModularFurniture/ProductCategories/5`}
                        >
                          Storage Units
                        </NavLink>
                      </li>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/ModularFurniture/ProductCategories/4`}
                        >
                          Consoles
                        </NavLink>
                      </li>

                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/ModularFurniture/ProductCategories/6`}
                        >
                          Office Furniture
                        </NavLink>
                      </li>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/ModularFurniture#MaterialsandFinishes`}
                        >
                          Materials and Finishes
                        </NavLink>
                      </li>

                      {/* <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`ModularFurniture/MaterialOptions/1`}
                        >
                          LaminatesLaminates
                        </NavLink>
                      </li>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`ModularFurniture/MaterialOptions/2`}
                        >
                          PU
                        </NavLink>
                      </li>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`ModularFurniture/MaterialOptions/3`}
                        >
                          Acrylic
                        </NavLink>
                      </li>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`ModularFurniture/MaterialOptions/4`}
                        >
                          Glass
                        </NavLink>
                      </li>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`ModularFurniture/MaterialOptions/5`}
                        >
                          Thermofoil
                        </NavLink>
                      </li> */}
                    </ul>
                  </li>
                  <li className="menu-item sub__menus__arrows">
                    <NavLink
                      onClick={toggleClass}
                      activeClassName="is-active"
                      to={`/Granite`}
                    >
                      Granite
                    </NavLink>

                    {/* <ul className={boxClassSubMenu.join(" ")}>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/Granite/ProductCategories/1`}
                        >
                          Colours
                        </NavLink>
                      </li>
                       <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/Granite/ProductCategories/2`}
                        >
                          Products
                        </NavLink>
                      </li> 
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/Granite/ProductCategories/3`}
                        >
                          Finishes
                        </NavLink>
                      </li>
                    </ul> */}
                  </li>
                  <li
                    onClick={toggleSubmenu}
                    className="menu-item sub__menus__arrows"
                  >
                    <NavLink
                      onClick={toggleClass}
                      activeClassName="is-active"
                      to={`/Quartz`}
                    >
                      Quartz <FiChevronDown />
                    </NavLink>

                    <ul className={boxClassSubMenu.join(" ")}>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/QuartzProducts`}
                        >
                          Products
                        </NavLink>
                      </li>
                      {/* <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/EdgeProfiles`}
                        >
                          Edge Profiles
                        </NavLink>
                      </li> */}
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`Quartz/ColourRange`}
                        >
                          Colour Range
                        </NavLink>
                      </li>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/OurProcess`}
                        >
                          Our Process
                        </NavLink>
                      </li>
                    </ul>
                  </li>

                  <li
                    onClick={toggleSubmenu}
                    className="menu-item sub__menus__arrows"
                  >
                    <NavLink
                      onClick={toggleClass}
                      activeClassName="is-active"
                      to={`/Export`}
                    >
                      Projects <FiChevronDown />
                    </NavLink>

                    <ul className={boxClassSubMenu.join(" ")}>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/DivineSpacesGlobal`}
                        >
                          Quartz
                        </NavLink>
                      </li>

                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/GraniteGlobal`}
                        >
                          Granite
                        </NavLink>
                      </li>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/RostreCabinetsGlobal`}
                        >
                          Cabinets
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  {/* <li className="menu-item" onClick={scrollToTop}>
                    <NavLink
                      exact
                      activeClassName="is-active"
                      onClick={toggleClass}
                      to={`/Export`}
                    >
                      Global
                    </NavLink>
                  </li> */}
                  {/* <li className="menu-item" onClick={scrollToTop}>
                    <NavLink
                      exact
                      activeClassName="is-active"
                      onClick={toggleClass}
                      to={`/Projects`}
                    >
                      Projects
                    </NavLink>
                  </li> */}
                  <li className="menu-item" onClick={scrollToTop}>
                    <NavLink
                      exact
                      activeClassName="is-active"
                      onClick={toggleClass}
                      to={`/Contactus`}
                    >
                      Contact Us
                    </NavLink>
                  </li>

                  {/* <li
                    onClick={toggleSubmenu}
                    className="menu-item sub__menus__arrows"
                  >
                    <Link to="#">
                      About Us <FiChevronDown />
                    </Link>

                    <ul className={boxClassSubMenu.join(" ")}>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/ModularFurniture`}
                        >
                          Modular Furniture
                        </NavLink>
                      </li>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/Granite`}
                        >
                          Granite
                        </NavLink>
                      </li>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/Quartz`}
                        >
                          Quartz
                        </NavLink>
                      </li>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/ContactUs`}
                        >
                          Contact Us
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/AwardsAchievement`}
                        >
                          Awards & Achievement
                        </NavLink>
                      </li>
                    </ul>
                  </li> */}

                  {/* <li className="menu-item" onClick={scrollToTop}>
                    <NavLink
                      exact
                      activeClassName="is-active"
                      onClick={toggleClass}
                      to={`/Aboutus`}
                    >
                      About Us
                    </NavLink>
                  </li>

                  <li className="menu-item" onClick={scrollToTop}>
                    <NavLink
                      exact
                      activeClassName="is-active"
                      onClick={toggleClass}
                      to={`/ModularFurniture`}
                    >
                      Modular Furniture
                    </NavLink>
                  </li>

                  <li className="menu-item" onClick={scrollToTop}>
                    <NavLink
                      exact
                      activeClassName="is-active"
                      onClick={toggleClass}
                      to={`/Granite`}
                    >
                      Granite
                    </NavLink>
                  </li>

                  <li className="menu-item" onClick={scrollToTop}>
                    <NavLink
                      exact
                      activeClassName="is-active"
                      onClick={toggleClass}
                      to={`/Quartz`}
                    >
                      Quartz
                    </NavLink>
                  </li> */}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
