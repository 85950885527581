const QuartzcolourRangeDetailsData = {
  FebruusBeige4020: {
    name: "4020 Februus Beige",
    details: "This is a beautiful beige quartz color.",
    images: [
      { src: "/images/ColourRange/Argento/DetailsPageImages/details-4020.png" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Argento/DetailsPageImages/sub-image-1.png" },
      // { src: "/images/ColourRange/Argento/DetailsPageImages/4021-sub-images-2.jpg", alt: "Full slab" },
    ],
    productsData: [
      {
        color: 'Brown',
        countryOfOrigin: 'India',
        colourName: 'Februus Beige',
        colourCode: '4020',
        stoneForm: 'Slabs and Cut to size as per requirement',
        stoneFinish: 'Polished, Leather, Honed',
        size: '3050*1120 mm / Other custom sizes are possible',
        thickness: '15 mm, 20 mm, 30 mm'
      }
    ],
    basicParams: [
      {
        absorptionByWeight: '0.06%',
        appDensityKG: '>2100',
        abrasionResistance: '34',
        modulusOfRupture: '55 - 65',
        flexuralStrength: '40 - 60',
        compressStrength: '150 - 250',
        mohsHardness: '6.0 - 7.0',
        specularGloss: '55 - 70'
      }
    ]
  },
  MonetaBeige4021: {
    name: "4021 Moneta Beige",
    images: [
      { src: "/images/ColourRange/Argento/DetailsPageImages/details-4021.png", alt: "4021 Moneta Beige" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Argento/DetailsPageImages/4021-sub-images-1.jpg", alt: "clouse-up", name: "close-up" },
      { src: "/images/ColourRange/Argento/DetailsPageImages/4021-sub-images-2.jpg", alt: "Full slab", name: "Full Slab" },
    ],
    productsData: [
      {
        color: 'Brown',
        countryOfOrigin: 'India',
        colourName: 'Moneta Beige',
        colourCode: '4021',
        stoneForm: 'Slabs and Cut to size as per requirement',
        stoneFinish: 'Polished, Leather, Honed',
        size: '3050*1120 mm / Other custom sizes are possible',
        thickness: '15 mm, 20 mm, 30 mm'
      }
    ],
    basicParams: [
      {
        absorptionByWeight: '0.06%',
        appDensityKG: '>2100',
        abrasionResistance: '34',
        modulusOfRupture: '55 - 65',
        flexuralStrength: '40 - 60',
        compressStrength: '150 - 250',
        mohsHardness: '6.0 - 7.0',
        specularGloss: '55 - 70'
      }
    ]
  },
  BiamcoPepe5005: {
    name: "5005 Biamco Pepe",
    images: [
      { src: "/images/ColourRange/Argento/DetailsPageImages/details-5005.jpg", alt: "5005 Biamco Pepe" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Argento/DetailsPageImages/5005-sub-images-1.jpg", alt: "clouse-up", name: "close-up" },
      { src: "/images/ColourRange/Argento/DetailsPageImages/5005-sub-images-2.jpg", alt: "Full slab", name: "Full Slab" },
    ],
    productsData: [
      {
        color: 'Cream',
        countryOfOrigin: 'India',
        colourName: 'Bianco Pepe',
        colourCode: '5005',
        stoneForm: 'Slabs and Cut to size as per requirement',
        stoneFinish: 'Polished, Leather, Honed',
        size: '3050*1120 mm / Other custom sizes are possible',
        thickness: '15 mm, 20 mm, 30 mm'
      }
    ],
    basicParams: [
      {
        absorptionByWeight: '0.06%',
        density: '2329 Kg/m3',
        abrasionResistance: '34',
        modulusOfRupture: {
          dryCondition: '61.9 Mpa',
          wetCondition: '63.8 Mpa'
        },
        flexuralStrength: {
          dryCondition: '49.7 Mpa',
          wetCondition: '54.1 Mpa'
        },
        compressStrength: {
          dryCondition: '221 Mpa',
          wetCondition: '227 Mpa'
        },
        mohsHardness: '6',
        specularGloss: '48.8'
      }
    ]
  },
  AradiaBeige5008: {
    name: "5008 Aradia Beige",
    images: [
      { src: "/images/ColourRange/Argento/DetailsPageImages/details-5008.jpg", alt: "5008 Aradia Beige" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Argento/DetailsPageImages/5008-sub-images-1.jpg", alt: "clouse-up", name: "close-up" },
      { src: "/images/ColourRange/Argento/DetailsPageImages/5008-sub-images-2.jpg", alt: "Full slab", name: "Full Slab" },
    ],
    productsData: [
      {
        color: 'Cream',
        countryOfOrigin: 'India',
        colourName: 'Aradia Beige',
        colourCode: '5008',
        stoneForm: 'Slabs and Cut to size as per requirement',
        stoneFinish: 'Polished, Leather, Honed',
        size: '3050*1120 mm / Other custom sizes are possible',
        thickness: '15 mm, 20 mm, 30 mm'
      }
    ],
    basicParams: [
      {
        absorptionByWeight: '0.06%',
        density: '2329 Kg/m³',
        abrasionResistance: '34',
        modulusOfRupture: {
          dryCondition: '61.9 MPa',
          wetCondition: '63.8 MPa'
        },
        flexuralStrength: {
          dryCondition: '49.7 MPa',
          wetCondition: '54.1 MPa'
        },
        compressStrength: {
          dryCondition: '221 MPa',
          wetCondition: '227 MPa'
        },
        mohsHardness: '6',
        specularGloss: '48.8'
      }
    ]
  },
  GrigioCristallo6015: {
    name: "6015 Grigio Cristallo",
    images: [
      { src: "/images/ColourRange/Argento/DetailsPageImages/6015-details.jpg", alt: "6015 Grigio Cristallo" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Argento/DetailsPageImages/6015-sub-images-1.jpg", alt: "clouse-up", name: "close-up" },
      { src: "/images/ColourRange/Argento/DetailsPageImages/6015-sub-images-2.jpg", alt: "Full slab", name: "Full Slab" },
    ],
    productsData: [
      {
        color: 'Grey',
        countryOfOrigin: 'India',
        colourName: 'Grigio Cristallo',
        colourCode: '6015',
        stoneForm: 'Slabs and Cut to size as per requirement',
        stoneFinish: 'Polished, Leather, Honed',
        size: '3050*1120 mm / Other custom sizes are possible',
        thickness: '15 mm, 20 mm, 30 mm'
      }
    ],
    basicParams: [
      {
        absorptionByWeight: '0.06%',
        appDensityKG: '>2100',
        abrasionResistance: '34',
        modulusOfRupture: '55 - 65',
        flexuralStrength: '40 - 60',
        compressStrength: '150 - 250',
        mohsHardness: '6.0 - 7.0',
        specularGloss: '55 - 70'
      }
    ]

  },
  GrigioCristallo6016: {
    name: "6016 Grigio Cristallo",
    images: [
      { src: "/images/ColourRange/Argento/DetailsPageImages/details-6016.jpg", alt: "6016 Grigio Cristallo" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Argento/DetailsPageImages/6016-sub-image-1.jpg", alt: "clouse-up", name: "close-up" },
      { src: "/images/ColourRange/Argento/DetailsPageImages/6016-sub-image-2.jpg", alt: "Full slab", name: "Full Slab" },
    ],
    productsData: [
      {
        color: 'Brown',
        countryOfOrigin: 'India',
        colourName: 'Grigio Cristallo',
        colourCode: '6016',
        stoneForm: 'Slabs and Cut to size as per requirement',
        stoneFinish: 'Polished, Leather, Honed',
        size: '3050*1120 mm / Other custom sizes are possible',
        thickness: '15 mm, 20 mm, 30 mm'
      }
    ],
    basicParams: [
      {
        absorptionByWeight: '0.06%',
        appDensityKG: '>2100',
        abrasionResistance: '34',
        modulusOfRupture: '55 - 65',
        flexuralStrength: '40 - 60',
        compressStrength: '150 - 250',
        mohsHardness: '6.0 - 7.0',
        specularGloss: '55 - 70'
      }
    ]
  },
  AradiaGrigio6025: {
    name: "6025 Aradia Grigio",
    images: [
      { src: "/images/ColourRange/Argento/DetailsPageImages/detail-image-6025.jpg", alt: "6025 Aradia Grigio" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Argento/DetailsPageImages/6025-sub-image-1.jpg", alt: "clouse-up", name: "close-up" },
      { src: "/images/ColourRange/Argento/DetailsPageImages/6025-sub-image-2jpg.jpg", alt: "Full slab", name: "Full Slab" },
    ],
    productsData: [
      {
        color: 'Grey',
        countryOfOrigin: 'India',
        colourName: 'Aradia Grigio',
        colourCode: '6025',
        stoneForm: 'Slabs and Cut to size as per requirement',
        stoneFinish: 'Polished, Leather, Honed',
        size: '3050*1120 mm / Other custom sizes are possible',
        thickness: '15 mm, 20 mm, 30 mm'
      }
    ],
    basicParams: [
      {
        absorptionByWeight: '0.06%',
        appDensityKG: '>2100',
        abrasionResistance: '34',
        modulusOfRupture: '55 - 65',
        flexuralStrength: '40 - 60',
        compressStrength: '150 - 250',
        mohsHardness: '6.0 - 7.0',
        specularGloss: '55 - 70'
      }
    ]
  },
  GrigioArmani6029: {
    name: "6029 Grigio Armani",
    images: [
      { src: "/images/ColourRange/Argento/DetailsPageImages/details-6029.jpg", alt: "6029 Grigio Armani" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Argento/DetailsPageImages/6029-sub-image-1.jpg", alt: "clouse-up", name: "close-up" },
      { src: "/images/ColourRange/Argento/DetailsPageImages/6029-sub-image-2.jpg", alt: "Full slab", name: "Full Slab" },
    ],
    productsData: [
      {
        color: 'Brown',
        countryOfOrigin: 'India',
        colourName: 'Grigio Armani',
        colourCode: '6029',
        stoneForm: 'Slabs and Cut to size as per requirement',
        stoneFinish: 'Polished, Leather, Honed',
        size: '3050*1120 mm / Other custom sizes are possible',
        thickness: '15 mm, 20 mm, 30 mm'
      }
    ],
    basicParams: [
      {
        absorptionByWeight: '0.06%',
        appDensityKG: '>2100',
        abrasionResistance: '34',
        modulusOfRupture: '55 - 65',
        flexuralStrength: '40 - 60',
        compressStrength: '150 - 250',
        mohsHardness: '6.0 - 7.0',
        specularGloss: '55 - 70'
      }
    ]
  },

  ///Bronze details Data 
  CiottoloBeige5004: {
    name: "5004 Ciottolo Beige",
    images: [
      { src: "/images/ColourRange/Bronzo/bronzoDetails/5004-detail-img.jpg", alt: "5004 Ciottolo Beige" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Bronzo/bronzoDetails/5004-detail-sub-img-1.jpg", alt: "clouse-up", name: "close-up" },
      { src: "/images/ColourRange/Bronzo/bronzoDetails/5004-detail-sub-img-2.jpg", alt: "Full slab", name: "Full Slab" },
    ],
    productsData: [
      {
        color: 'Beige',
        countryOfOrigin: 'India',
        colourName: 'Ciottolo Beige',
        colourCode: '5004',
        stoneForm: 'Slabs and Cut to size as per requirement',
        stoneFinish: 'Polished, Leather, Honed',
        size: '3050*1120 mm / Other custom sizes are possible',
        thickness: '15 mm, 20 mm, 30 mm'
      }
    ],
    basicParams: [
      {
        absorptionByWeight: '0.06%',
        appDensityKG: '>2100',
        abrasionResistance: '34',
        modulusOfRupture: '55 - 65',
        flexuralStrength: '40 - 60',
        compressStrength: '150 - 250',
        mohsHardness: '6.0 - 7.0',
        specularGloss: '55 - 70'
      }
    ]
  },
  Cemento6027: {
    name: "6027 Cemento",
    images: [
      { src: "/images/ColourRange/Bronzo/bronzoDetails/6027-details-img.jpg", alt: "6027 Cemento" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Bronzo/bronzoDetails/6027-details-sub-img-1.jpg", alt: "clouse-up", name: "close-up" },
      { src: "/images/ColourRange/Bronzo/bronzoDetails/6027-details-sub-img-2.jpg", alt: "Full slab", name: "Full Slab" },
    ],
    productsData: [
      {
        color: 'Brown',
        countryOfOrigin: 'India',
        colourName: 'Cemento',
        colourCode: '6027',
        stoneForm: 'Slabs and Cut to size as per requirement',
        stoneFinish: 'Polished, Leather, Honed',
        size: '3050*1120 mm / Other custom sizes are possible',
        thickness: '15 mm, 20 mm, 30 mm'
      }
    ],
    basicParams: [
      {
        absorptionByWeight: '0.06%',
        appDensityKG: '>2100',
        abrasionResistance: '34',
        modulusOfRupture: '55 - 65',
        flexuralStrength: '40 - 60',
        compressStrength: '150 - 250',
        mohsHardness: '6.0 - 7.0',
        specularGloss: '55 - 70'
      }
    ]
  },


  //Palladio details data 
  //0.1
  CalacattaSottile8021: {
    name: "8021 Calacatta Sottile",
    images: [
      { src: "/images/ColourRange/Palladio/PalladioDetails/8021-details-img.jpg", alt: "8021 Calacatta Sottile" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Palladio/PalladioDetails/8021-details-sub-img-1.jpg", alt: "clouse-up", name: "close-up" },
      { src: "/images/ColourRange/Palladio/PalladioDetails/8021-details-sub-img-2.jpg", alt: "Full slab", name: "Full Slab" },
      { src: "/images/ColourRange/Palladio/PalladioDetails/8021-details-sub-img-3.jpg", alt: "Application", name: "Application" },
    ],
    productsData: [
      {
        color: 'White',
        countryOfOrigin: 'India',
        colourName: 'Calacatta Sottile',
        colourCode: '8021',
        stoneForm: 'Slabs and Cut to size as per requirement',
        stoneFinish: 'Polished, Leather, Honed',
        size: '3050*1120 mm / Other custom sizes are possible',
        thickness: '15 mm, 20 mm, 30 mm'
      }
    ],
    basicParams: [
      {
        absorptionByWeight: '0.06%',
        appDensityKG: '>2100',
        abrasionResistance: '34',
        modulusOfRupture: '55 - 65',
        flexuralStrength: '40 - 60',
        compressStrength: '150 - 250',
        mohsHardness: '6.0 - 7.0',
        specularGloss: '55 - 70'
      }
    ]
  },
  //.2
  CalacattaEsotico8022: {
    name: "8022 Calacatta Esotico",
    images: [
      { src: "/images/ColourRange/Palladio/PalladioDetails/8022-details-img.jpg", alt: "8022 Calacatta Esotico" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Palladio/PalladioDetails/8022-details-sub-img-1.jpg", alt: "clouse-up", name: "close-up" },
      { src: "/images/ColourRange/Palladio/PalladioDetails/8022-details-sub-img-2.jpg", alt: "Full slab", name: "Full Slab" },
      { src: "/images/ColourRange/Palladio/PalladioDetails/8022-details-sub-img-3.jpg", alt: "Application", name: "Application" },
    ],
    productsData: [
      {
        color: 'White',
        countryOfOrigin: 'India',
        colourName: 'Calacatta Esotico',
        colourCode: '8022',
        stoneForm: 'Slabs and Cut to size as per requirement',
        stoneFinish: 'Polished, Leather, Honed',
        size: '3050*1120 mm / Other custom sizes are possible',
        thickness: '15 mm, 20 mm, 30 mm'
      }
    ],
    basicParams: [
      {
        absorptionByWeight: '0.06%',
        appDensityKG: '>2100',
        abrasionResistance: '34',
        modulusOfRupture: '55 - 65',
        flexuralStrength: '40 - 60',
        compressStrength: '150 - 250',
        mohsHardness: '6.0 - 7.0',
        specularGloss: '55 - 70'
      }
    ]
  },
  //3.
  CalacattaAshNova8023: {
    name: "8023 Calacatta Ash Nova",
    images: [
      { src: "/images/ColourRange/Palladio/PalladioDetails/8023-details-img.jpg", alt: "8023 Calacatta Ash Nova" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Palladio/PalladioDetails/8023-details-sub-img.jpg", alt: "clouse-up", name: "close-up" },
      { src: "/images/ColourRange/Palladio/PalladioDetails/8023-details-sub-img-2.jpg", alt: "Full slab", name: "Full Slab" },
      //   { src: "/images/ColourRange/Palladio/PalladioDetails/", alt: "Application" , name : "Application" },
    ],
    productsData: [
      {
        color: 'White',
        countryOfOrigin: 'India',
        colourName: 'Calacatta Ash Nova',
        colourCode: '8023',
        stoneForm: 'Slabs and Cut to size as per requirement',
        stoneFinish: 'Polished, Leather, Honed',
        size: '3050*1120 mm / Other custom sizes are possible',
        thickness: '15 mm, 20 mm, 30 mm'
      }
    ],
    basicParams: [
      {
        absorptionByWeight: '0.06%',
        appDensityKG: '>2100',
        abrasionResistance: '34',
        modulusOfRupture: '55 - 65',
        flexuralStrength: '40 - 60',
        compressStrength: '150 - 250',
        mohsHardness: '6.0 - 7.0',
        specularGloss: '55 - 70'
      }
    ]
  },
  //.4
  CalacattaAshen8024: {
    name: "8024 Calacatta Ashen",
    images: [
      { src: "/images/ColourRange/Palladio/PalladioDetails/8024-details-img.jpg", alt: "8024 Calacatta Ashen" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Palladio/PalladioDetails/8024-details-sub-img-1.jpg", alt: "clouse-up", name: "close-up" },
      { src: "/images/ColourRange/Palladio/PalladioDetails/8024-details-sub-img-2.jpg", alt: "Full slab", name: "Full Slab" },
      //   { src: "/images/ColourRange/Palladio/PalladioDetails/", alt: "Application" , name : "Application" },
    ],
    productsData: [
      {
        color: 'White',
        countryOfOrigin: 'India',
        colourName: 'Calacatta Ash Nova',
        colourCode: '8023',
        stoneForm: 'Slabs and Cut to size as per requirement',
        stoneFinish: 'Polished, Leather, Honed',
        size: '3050*1120 mm / Other custom sizes are possible',
        thickness: '15 mm, 20 mm, 30 mm'
      }
    ],
    basicParams: [
      {
        absorptionByWeight: '0.06%',
        appDensityKG: '>2100',
        abrasionResistance: '34',
        modulusOfRupture: {
          range: '55 - 65'
        },
        flexuralStrength: {
          range: '40 - 60'
        },
        compressStrength: {
          range: '150 - 250'
        },
        mohsHardness: '6.0 - 7.0',
        specularGloss: '55 - 70'
      }
    ]
  },
  //.5
  CalacattaDusky8025: {
    name: "8025 Calacatta Dusky",
    images: [
      { src: "/images/ColourRange/Palladio/PalladioDetails/8025-details-img.jpg", alt: "8025 Calacatta Dusky" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Palladio/PalladioDetails/8025-details-sub-img-1.jpg", alt: "clouse-up", name: "close-up" },
      { src: "/images/ColourRange/Palladio/PalladioDetails/8025-details-sub-img-2.jpg", alt: "Full slab", name: "Full Slab" },
      // { src: "/images/ColourRange/Palladio/PalladioDetails/", alt: "Application" , name : "Application" },
    ],
    productsData: [
      {
        color: 'White',
        countryOfOrigin: 'India',
        colourName: 'Calacatta Dusky',
        colourCode: '8025',
        stoneForm: 'Slabs and Cut to size as per requirement',
        stoneFinish: 'Polished, Leather, Honed',
        size: '3050*1120 mm / Other custom sizes are possible',
        thickness: '15 mm, 20 mm, 30 mm'
      }
    ],
    basicParams: [
      {
        absorptionByWeight: '0.06%',
        appDensityKG: '>2100',
        abrasionResistance: '34',
        modulusOfRupture: {
          range: '55 - 65'
        },
        flexuralStrength: {
          range: '40 - 60'
        },
        compressStrength: {
          range: '150 - 250'
        },
        mohsHardness: '6.0 - 7.0',
        specularGloss: '55 - 70'
      }
    ]
  },
  //.6
  CalacattaSmoky8026: {
    name: "8026 Calacatta Smok",
    images: [
      { src: "/images/ColourRange/Palladio/PalladioDetails/8026-details-img.jpg", alt: "8026 Calacatta Smok" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Palladio/PalladioDetails/8026-details-sub-img-1.jpg", alt: "clouse-up", name: "close-up" },
      { src: "/images/ColourRange/Palladio/PalladioDetails/8026-details-sub-img-2.jpg", alt: "Full slab", name: "Full Slab" },
      // { src: "/images/ColourRange/Palladio/PalladioDetails/", alt: "Application" , name : "Application" },
    ],
    productsData: [
      {
        color: 'White',
        size: '127 x 64',
        countryOfOrigin: 'India',
        colourName: 'Calacatta Smoky',
        colourCode: '8026',
        stoneForm: 'Slabs and Cut to size as per requirement',
        stoneFinish: 'Polished, Leather, Honed',
        sizeDetails: '3050*1120 mm / Other custom sizes are possible',
        thickness: '15 mm, 20 mm, 30 mm'
      }
    ],
    basicParams: [
      {
        absorptionByWeight: '0.06%',
        appDensityKG: '>2100',
        abrasionResistance: '34',
        modulusOfRupture: {
          range: '55 - 65'
        },
        flexuralStrength: {
          range: '40 - 60'
        },
        compressStrength: {
          range: '150 - 250'
        },
        mohsHardness: '6.0 - 7.0',
        specularGloss: '55 - 70'
      }
    ]
  },

  // <-----------------------------!!!!--------------------------------->
  //platino
  //.1
  CarraraOroe8019B: {
    name: "8019B Carrara Oroe",
    images: [
      { src: "/images/ColourRange/Platino/PlatinoDetails/8019-details-img.jpg", alt: "8019B Carrara Oroe" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Platino/PlatinoDetails/8019-details-sub-img-1.jpg", alt: "clouse-up", name: "close-up" },
      { src: "/images/ColourRange/Platino/PlatinoDetails/8019-details-sub-img-2.jpg", alt: "Full slab", name: "Full Slab" },
      { src: "/images/ColourRange/Palladio/PalladioDetails/8019-details-sub-img-3.jpg", alt: "Application", name: "Application" },
    ],
    productsData: [
      {
        color: 'White',
        countryOfOrigin: 'India',
        colourName: 'Carrara Oro',
        colourCode: '8019B',
        stoneForm: 'Slabs and Cut to size as per requirement',
        stoneFinish: 'Polished, Leather, Honed',
        sizeDetails: '3050*1120 mm / Other custom sizes are possible',
        thickness: '15 mm, 20 mm, 30 mm'
      }
    ],
    basicParams: [
      {
        absorptionByWeight: '0.06%',
        appDensityKG: '>2100',
        abrasionResistance: '34',
        modulusOfRupture: {
          range: '55 - 65'
        },
        flexuralStrength: {
          range: '40 - 60'
        },
        compressStrength: {
          range: '150 - 250'
        },
        mohsHardness: '6.0 - 7.0',
        specularGloss: '55 - 70'
      }
    ]
  },
  //.2
  CarraraLinea8020: {
    name: "8020 Carrara Linea",
    images: [
      { src: "/images/ColourRange/Platino/PlatinoDetails/8020-details-img.jpg", alt: "8020 Carrara Linea" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Platino/PlatinoDetails/8020-details-sub-img-1.jpg", alt: "clouse-up", name: "close-up" },
      { src: "/images/ColourRange/Platino/PlatinoDetails/8020-details-sub-img-2.jpg", alt: "Full slab", name: "Full Slab" },
      // { src: "/images/ColourRange/Palladio/PalladioDetails/", alt: "Application" , name : "Application" },
    ],
    productsData: [
      {
        color: 'White',
        countryOfOrigin: 'India',
        colourName: 'Carrara Oro',
        colourCode: '8019B',
        stoneForm: 'Slabs and Cut to size as per requirement',
        stoneFinish: 'Polished, Leather, Honed',
        sizeDetails: '3050*1120 mm / Other custom sizes are possible',
        thickness: '15 mm, 20 mm, 30 mm'
      }
    ],
    basicParams: [
      {
        absorptionByWeight: '0.06%',
        appDensityKG: '>2100',
        abrasionResistance: '34',
        modulusOfRupture: {
          range: '55 - 65'
        },
        flexuralStrength: {
          range: '40 - 60'
        },
        compressStrength: {
          range: '150 - 250'
        },
        mohsHardness: '6.0 - 7.0',
        specularGloss: '55 - 70'
      }
    ]
  },
  //.3
  PiatraGrizzle6031: {
    name: "6031 Piatra Grizzle",
    images: [
      { src: "/images/ColourRange/Platino/PlatinoDetails/6031-details-img.jpg", alt: "6031 Piatra Grizzle" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Platino/PlatinoDetails/6031-details-sub-img-1.jpg", alt: "clouse-up", name: "close-up" },
      { src: "/images/ColourRange/Platino/PlatinoDetails/6031-details-sub-img-2.jpg", alt: "Full slab", name: "Full Slab" },
      // { src: "/images/ColourRange/Palladio/PalladioDetails/", alt: "Application" , name : "Application" },
    ],
    productsData: [
      {
        color: 'Brown',
        countryOfOrigin: 'India',
        colourName: 'Piatra Grizzle',
        colourCode: '6031',
        stoneForm: 'Slabs and Cut to size as per requirement',
        stoneFinish: 'Polished, Leather, Honed',
        sizeDetails: '3050*1120 mm / Other custom sizes are possible',
        thickness: '15 mm, 20 mm, 30 mm'
      }
    ],
    basicParams: [
      {
        absorptionByWeight: '0.06%',
        appDensityKG: '>2100',
        abrasionResistance: '34',
        modulusOfRupture: {
          range: '55 - 65'
        },
        flexuralStrength: {
          range: '40 - 60'
        },
        compressStrength: {
          range: '150 - 250'
        },
        mohsHardness: '6.0 - 7.0',
        specularGloss: '55 - 70'
      }
    ]
  },

  //<---------------------------!!!!------------------------------------>
  //Oro
  //1
  BiancoAcero9020: {
    name: "9020 Bianco Acero",
    images: [
      { src: "/images/ColourRange/Oro/OroDetails/9020-details-img.jpg", alt: "9020 Bianco Acero" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Oro/OroDetails/9020-details-sub-img-1.jpg", alt: "clouse-up", name: "close-up" },
      { src: "/images/ColourRange/Oro/OroDetails/9020-details-sub-img-2.jpg", alt: "Full slab", name: "Full Slab" },
      // { src: "/images/ColourRange/Palladio/PalladioDetails/", alt: "Application" , name : "Application" },
    ],
    productsData: [
      {
          color: "White",
          countryOfOrigin: "India",
          colourName: "Bianco Acero",
          colourCode: "9020",
          stoneForm: "Slabs and Cut to size as per requirement",
          stoneFinish: "Polished, Leather, Honed",
          size: "3050*1120 mm / Other custom sizes are possible",
          thickness: "15 mm, 20 mm, 30 mm",
      }
  ],
  basicParams: [
      {
          absorptionByWeight: "0.06%",
          appDensityKG: ">2100",
          abrasionResistance: "34",
          modulusOfRupture: "55 - 65",
          flexuralStrength: "40 - 60",
          compressStrength: "150 - 250",
          mohsHardness: "6.0 - 7.0",
          specularGloss: "55 - 70",
      }
  ]
    
  },
  //.2
  AradiaBianca4014: {
    name: "4014 Aradia Bianca",
    images: [
      { src: "/images/ColourRange/Oro/OroDetails/4014-details-img.jpg", alt: "4014 Aradia Bianca" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Oro/OroDetails/4014-details-sub-img-1.jpg", alt: "Close-up", name: "Close-up" },
      { src: "/images/ColourRange/Oro/OroDetails/4014-details-sub-img-2.jpg", alt: "Full Slab", name: "Full Slab" },
      { src: "/images/ColourRange/Oro/OroDetails/4014-details-sub-img-3.jpg", alt: "Application", name: "Application" },
    ],
    productsData: [
      {
          color: "White",
          countryOfOrigin: "India",
          colourName: "Aradia Bianca",
          colourCode: "4014",
          stoneForm: "Slabs and Cut to size as per requirement",
          stoneFinish: "Polished, Leather, Honed",
          size: "3050*1120 mm / Other custom sizes are possible",
          thickness: "15 mm, 20 mm, 30 mm",
      }
  ],
  basicParams: [
      {
          absorptionByWeight: "0.06%",
          appDensityKG: ">2100",
          abrasionResistance: "34",
          modulusOfRupture: "55 - 65",
          flexuralStrength: "40 - 60",
          compressStrength: "150 - 250",
          mohsHardness: "6.0 - 7.0",
          specularGloss: "55 - 70",
      }
  ]
  },
  //.3
  Cappuccino4019: {
    name: "4019 Cappuccino",
    images: [
      { src: "/images/ColourRange/Oro/OroDetails/4019-details-img.jpg", alt: "4019 Cappuccino" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Oro/OroDetails/4019-details-sub-img-1.jpg", alt: "Close-up", name: "Close-up" },
      { src: "/images/ColourRange/Oro/OroDetails/4019-details-sub-img-2.jpg", alt: "Full Slab", name: "Full Slab" },
    ],
    productsData: [
      {
          color: "Brown",
          countryOfOrigin: "India",
          colourName: "Cappuccino",
          colourCode: "4019",
          stoneForm: "Slabs and Cut to size as per requirement",
          stoneFinish: "Polished, Leather, Honed",
          size: "3050*1120 mm / Other custom sizes are possible",
          thickness: "15 mm, 20 mm, 30 mm",
      }
  ],
  basicParams: [
      {
          absorptionByWeight: "0.06%",
          appDensityKG: ">2100",
          abrasionResistance: "34",
          modulusOfRupture: "55 - 65",
          flexuralStrength: "40 - 60",
          compressStrength: "150 - 250",
          mohsHardness: "6.0 - 7.0",
          specularGloss: "55 - 70",
      }
  ]
  },
  //.4
  AradiaChocolati4022: {
    name: "4022 Aradia Chocolati",
    images: [
      { src: "/images/ColourRange/Oro/OroDetails/4022-details-img.jpg", alt: "4022 Aradia Chocolati" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Oro/OroDetails/4022-details-sub-img-1.jpg", alt: "Close-up", name: "Close-up" },
      { src: "/images/ColourRange/Oro/OroDetails/4022-details-sub-img-2.jpg", alt: "Full Slab", name: "Full Slab" },
    ],
    productsData: [
      {
          color: "Brown",
          countryOfOrigin: "India",
          colourName: "Aradia Chocolati",
          colourCode: "4022",
          stoneForm: "Slabs and Cut to size as per requirement",
          stoneFinish: "Polished, Leather, Honed",
          size: "3050*1120 mm / Other custom sizes are possible",
          thickness: "15 mm, 20 mm, 30 mm",
      }
  ],
  basicParams: [
      {
          absorptionByWeight: "0.06%",
          appDensityKG: ">2100",
          abrasionResistance: "34",
          modulusOfRupture: "55 - 65",
          flexuralStrength: "40 - 60",
          compressStrength: "150 - 250",
          mohsHardness: "6.0 - 7.0",
          specularGloss: "55 - 70",
      }
  ]
  },
  //.5
  CeresBeige5009: {
    name: "5009 Ceres Beige",
    images: [
      { src: "/images/ColourRange/Oro/OroDetails/5009-details-img.jpg", alt: "5009 Ceres Beige" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Oro/OroDetails/5009-details-sub-img-1.jpg", alt: "Close-up", name: "Close-up" },
      { src: "/images/ColourRange/Oro/OroDetails/5009-details-sub-img-2.jpg", alt: "Full Slab", name: "Full Slab" },
    ],
    productsData: [
      {
          color: "White",
          countryOfOrigin: "India",
          colourName: "Ceres Beige",
          colourCode: "5009",
          stoneForm: "Slabs and Cut to size as per requirement",
          stoneFinish: "Polished, Leather, Honed",
          size: "3050*1120 mm / Other custom sizes are possible",
          thickness: "15 mm, 20 mm, 30 mm",
      }
  ],
  basicParams: [
      {
          absorptionByWeight: "0.06%",
          appDensityKG: ">2100",
          abrasionResistance: "34",
          modulusOfRupture: "55 - 65",
          flexuralStrength: "40 - 60",
          compressStrength: "150 - 250",
          mohsHardness: "6.0 - 7.0",
          specularGloss: "55 - 70",
      }
  ]
  },
  //.6
  GrigioPuro6023: {
    name: "6023 Grigio Puro",
    images: [
      { src: "/images/ColourRange/Oro/OroDetails/6023-details-img.jpg", alt: "6023 Grigio Puro" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Oro/OroDetails/6023-details-sub-img-1.jpg", alt: "Close-up", name: "Close-up" },
      { src: "/images/ColourRange/Oro/OroDetails/6023-details-sub-img-2.jpg", alt: "Full Slab", name: "Full Slab" },
    ],
    productsData: [
      {
          color: "Grey",
          countryOfOrigin: "India",
          colourName: "Grigio Puro",
          colourCode: "6023",
          stoneForm: "Slabs and Cut to size as per requirement",
          stoneFinish: "Polished, Leather, Honed",
          size: "3050*1120 mm / Other custom sizes are possible",
          thickness: "15 mm, 20 mm, 30 mm",
      }
  ],
  basicParams: [
      {
          absorptionByWeight: "0.06%",
          appDensityKG: ">2100",
          abrasionResistance: "34",
          modulusOfRupture: "55 - 65",
          flexuralStrength: "40 - 60",
          compressStrength: "150 - 250",
          mohsHardness: "6.0 - 7.0",
          specularGloss: "55 - 70",
      }
  ]
  },
  //.7
  GrigioJapetus6024: {
    name: "6024 Grigio Japetus",
    images: [
      { src: "/images/ColourRange/Oro/OroDetails/6024-details-img.jpg", alt: "6024 Grigio Japetus" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Oro/OroDetails/6024-details-sub-img-1.jpg", alt: "Close-up", name: "Close-up" },
      { src: "/images/ColourRange/Oro/OroDetails/6024-details-sub-img-2.jpg", alt: "Full Slab", name: "Full Slab" },
    ],
    productsData: [
      {
          color: "Brown",
          countryOfOrigin: "India",
          colourName: "Grigio Japetus",
          colourCode: "6024",
          stoneForm: "Slabs and Cut to size as per requirement",
          stoneFinish: "Polished, Leather, Honed",
          size: "3050*1120 mm / Other custom sizes are possible",
          thickness: "15 mm, 20 mm, 30 mm",
      }
  ],
  basicParams: [
      {
          absorptionByWeight: "0.06%",
          appDensityKG: ">2100",
          abrasionResistance: "34",
          modulusOfRupture: "55 - 65",
          flexuralStrength: "40 - 60",
          compressStrength: "150 - 250",
          mohsHardness: "6.0 - 7.0",
          specularGloss: "55 - 70",
      }
  ]
  
  },
  //.8
  AradiaLivid6026: {
    name: "6026 Aradia Livid",
    images: [
      { src: "/images/ColourRange/Oro/OroDetails/6026-details-img.jpg", alt: "6026 Aradia Livid" },
    ],
    Subimages: [
      { src: "/images/ColourRange/Oro/OroDetails/6026-details-sub-img-1.jpg", alt: "Close-up", name: "Close-up" },
      { src: "/images/ColourRange/Oro/OroDetails/6026-details-sub-img-2.jpg", alt: "Full Slab", name: "Full Slab" },
    ],
    productsData: [
      {
          color: "Grey",
          countryOfOrigin: "India",
          colourName: "Aradia Livid",
          colourCode: "6026",
          stoneForm: "Slabs and Cut to size as per requirement",
          stoneFinish: "Polished, Leather, Honed",
          size: "3050*1120 mm / Other custom sizes are possible",
          thickness: "15 mm, 20 mm, 30 mm",
      }
  ],
  basicParams: [
      {
          absorptionByWeight: "0.06%",
          appDensityKG: ">2100",
          abrasionResistance: "34",
          modulusOfRupture: "55 - 65",
          flexuralStrength: "40 - 60",
          compressStrength: "150 - 250",
          mohsHardness: "6.0 - 7.0",
          specularGloss: "55 - 70",
      }
  ]
   
  },

  //.9
  GrigioChiaro6028: {
    name: "6028 Grigio Chiaro",
    images: [
      { "src": "/images/ColourRange/Oro/OroDetails/6028-details-img.jpg", "alt": "6028 Grigio Chiaro" }
    ],
    Subimages: [
      { "src": "/images/ColourRange/Oro/OroDetails/6028-details-sub-img-1.jpg", "alt": "Close-up", "name": "Close-up" },
      { "src": "/images/ColourRange/Oro/OroDetails/6028-details-sub-img-2.jpg", "alt": "Full Slab", "name": "Full Slab" }
    ],
    productsData: [
      {
          color: "Brown",
          countryOfOrigin: "India",
          colourName: "Grigio Chiaro",
          colourCode: "6028",
          stoneForm: "Slabs and Cut to size as per requirement",
          stoneFinish: "Polished, Leather, Honed",
          size: "3050*1120 mm / Other custom sizes are possible",
          thickness: "15 mm, 20 mm, 30 mm",
      }
  ],
  basicParams: [
      {
          absorptionByWeight: "0.06%",
          appDensityKG: ">2100",
          abrasionResistance: "34",
          modulusOfRupture: "55 - 65",
          flexuralStrength: "40 - 60",
          compressStrength: "150 - 250",
          mohsHardness: "6.0 - 7.0",
          specularGloss: "55 - 70",
      }
  ]
  },

  //.10
  Concerto6030: {
    name: "6030 Concerto",
    images: [
      { "src": "/images/ColourRange/Oro/OroDetails/6030-details-img.jpg", "alt": "6030 Concerto" }
    ],
    Subimages: [
      { "src": "/images/ColourRange/Oro/OroDetails/6030-details-sub-img-1.jpg", "alt": "Close-up", "name": "Close-up" },
      { "src": "/images/ColourRange/Oro/OroDetails/6030-details-sub-img-2.jpg", "alt": "Full Slab", "name": "Full Slab" }
    ],
    productsData: [
      {
          color: "Grey",
          countryOfOrigin: "India",
          colourName: "Concerto",
          colourCode: "6030",
          stoneForm: "Slabs and Cut to size as per requirement",
          stoneFinish: "Polished, Leather, Honed",
          size: "3050*1120 mm / Other custom sizes are possible",
          thickness: "15 mm, 20 mm, 30 mm",
      }
  ],
  basicParams: [
      {
          absorptionByWeight: "0.06%",
          appDensityKG: ">2100",
          abrasionResistance: "34",
          modulusOfRupture: "55 - 65",
          flexuralStrength: "40 - 60",
          compressStrength: "150 - 250",
          mohsHardness: "6.0 - 7.0",
          specularGloss: "55 - 70",
      }
  ]
  },

  //.11
  BiancoFortuna9019: {
    name: "9019 Bianco Fortuna",
    images: [
      { "src": "/images/ColourRange/Oro/OroDetails/9019-details-img.jpg", "alt": "9019 Bianco Fortuna" }
    ],
    Subimages: [
      { "src": "/images/ColourRange/Oro/OroDetails/9019-details-sub-img-1.jpg", "alt": "Close-up", "name": "Close-up" },
      { "src": "/images/ColourRange/Oro/OroDetails/9019-details-sub-img-2.jpg", "alt": "Full Slab", "name": "Full Slab" }
    ],
    productsData: [
      {
          color: "White",
          countryOfOrigin: "India",
          colourName: "Bianco Fortuna",
          colourCode: "9019",
          stoneForm: "Slabs and Cut to size as per requirement",
          stoneFinish: "Polished, Leather, Honed",
          size: "3050*1120 mm / Other custom sizes are possible",
          thickness: "15 mm, 20 mm, 30 mm",
      }
  ],
  basicParams: [
      {
          absorptionByWeight: "0.06%",
          appDensityKG: ">2100",
          abrasionResistance: "34",
          modulusOfRupture: "55 - 65",
          flexuralStrength: "40 - 60",
          compressStrength: "150 - 250",
          mohsHardness: "6.0 - 7.0",
          specularGloss: "55 - 70",
      }
  ]
   
  },

  //.12
  BiancoCristallo9022: {
    name: "9022 Bianco Cristallo",
    images: [
      { "src": "/images/ColourRange/Oro/OroDetails/9022-details-img.jpg", "alt": "9022 Bianco Cristallo" }
      ],
      Subimages: [
        { "src": "/images/ColourRange/Oro/OroDetails/9022-details-sub-img-1.jpg", "alt": "Close-up", "name": "Close-up" },
        { "src": "/images/ColourRange/Oro/OroDetails/9022-details-sub-img-2.jpg", "alt": "Full Slab", "name": "Full Slab" }
      ],
      productsData: [
        {
            color: "White",
            countryOfOrigin: "India",
            colourName: "Bianco Cristallo",
            colourCode: "9022",
            stoneForm: "Slabs and Cut to size as per requirement",
            stoneFinish: "Polished, Leather, Honed",
            size: "3050*1120 mm / Other custom sizes are possible",
            thickness: "15 mm, 20 mm, 30 mm",
        }
    ],
    basicParams: [
        {
            absorptionByWeight: "0.06%",
            appDensityKG: ">2100",
            abrasionResistance: "34",
            modulusOfRupture: "55 - 65",
            flexuralStrength: "40 - 60",
            compressStrength: "150 - 250",
            mohsHardness: "6.0 - 7.0",
            specularGloss: "55 - 70",
        }
    ]
   
  },

  //.13
  MiscellaArruzzo9024: {
    name: "9024 Miscella Arruzzo",
    images: [
      { "src": "/images/ColourRange/Oro/OroDetails/9024-details-img.jpg", "alt": "9024 Miscella Arruzzo" }
    ],
    Subimages: [
      { "src": "/images/ColourRange/Oro/OroDetails/9024-details-sub-img-1.jpg", "alt": "Close-up", "name": "Close-up" },
      { "src": "/images/ColourRange/Oro/OroDetails/9024-details-sub-img-2.jpg", "alt": "Full Slab", "name": "Full Slab" }
    ],
    productsData: [
      {
          color: "White",
          countryOfOrigin: "India",
          colourName: "Miscella Arruzzo",
          colourCode: "9024",
          stoneForm: "Slabs and Cut to size as per requirement",
          stoneFinish: "Polished, Leather, Honed",
          size: "3050*1120 mm / Other custom sizes are possible",
          thickness: "15 mm, 20 mm, 30 mm",
      }
  ],
  basicParams: [
      {
          absorptionByWeight: "0.06%",
          appDensityKG: ">2100",
          abrasionResistance: "34",
          modulusOfRupture: "55 - 65",
          flexuralStrength: "40 - 60",
          compressStrength: "150 - 250",
          mohsHardness: "6.0 - 7.0",
          specularGloss: "55 - 70",
      }
  ]
   
  },

 //<---------------------------!!!!------------------------------------>
  //Cobalto
  //1

  Nerissimo1050: {
    name: "1050 Nerissimo",
    images: [
        { src: "/images/ColourRange/Cobalto/CobaltoDetails/1050-details-img.jpg", alt: "1050 Nerissimo" },
    ],
    Subimages: [
        { src: "/images/ColourRange/Cobalto/CobaltoDetails/1050-details-sub-img-1.jpg", alt: "Close-up", name: "Close-up" },
        { src: "/images/ColourRange/Cobalto/CobaltoDetails/1050-details-sub-img-2.jpg", alt: "Full Slab", name: "Full Slab" },
        { src: "/images/ColourRange/Cobalto/CobaltoDetails/1050-details-sub-img-3.jpg", alt: "Application", name: "Application" },
    ],
    productsData: [
      {
          color: 'Black',
          countryOfOrigin: 'India',
          colourName: 'Nerissimo',
          colourCode: '1050',
          stoneForm: 'Slabs and Cut to size as per requirement',
          stoneFinish: 'Polished, Leather, Honed',
          size: '3050*1120 mm / Other custom sizes are possible',
          thickness: '15 mm, 20 mm, 30 mm'
      }
  ],
  basicParams: [
      {
          absorptionByWeight: '0.06%',
          appDensityKG: '>2100',
          abrasionResistance: '34',
          modulusOfRupture: '55 - 65',
          flexuralStrength: '40 - 60',
          compressStrength: '150 - 250',
          mohsHardness: '6.0 - 7.0',
          specularGloss: '55 - 70'
      }
  ]
  },
  //.2
  AradiaNero1051: {
    name: "1051 Aradia Nero",
    images: [
        { src: "/images/ColourRange/Cobalto/CobaltoDetails/1051-details-img.jpg", alt: "1051 Aradia Nero" },
    ],
    Subimages: [
        { src: "/images/ColourRange/Cobalto/CobaltoDetails/1051-details-sub-img-1.jpg", alt: "Close-up", name: "Close-up" },
        { src: "/images/ColourRange/Cobalto/CobaltoDetails/1051-details-sub-img-2.jpg", alt: "Full Slab", name: "Full Slab" },
    ],
    productsData: [
      {
          color: 'Black',
          countryOfOrigin: 'India',
          colourName: 'Aradia Nero',
          colourCode: '1051',
          stoneForm: 'Slabs and Cut to size as per requirement',
          stoneFinish: 'Polished, Leather, Honed',
          size: '3050*1120 mm / Other custom sizes are possible',
          thickness: '15 mm, 20 mm, 30 mm'
      }
  ],
  basicParams: [
      {
          absorptionByWeight: '0.06%',
          appDensityKG: '>2100',
          abrasionResistance: '34',
          modulusOfRupture: '55 - 65',
          flexuralStrength: '40 - 60',
          compressStrength: '150 - 250',
          mohsHardness: '6.0 - 7.0',
          specularGloss: '55 - 70'
      }
  ]
  
  },
  //.3
  BlackVein1052: {
    name: "1052 Black Vein",
    images: [
        { src: "/images/ColourRange/Cobalto/CobaltoDetails/1052-details-img.jpg", alt: "1052 Black Vein" },
    ],
    Subimages: [
        { src: "/images/ColourRange/Cobalto/CobaltoDetails/1052-details-sub-img-1.jpg", alt: "Full Slab", name: "Full Slab" },
        // { src: "/images/ColourRange/Cobalto/CobaltoDetails/", alt: "Full Slab", name: "Full Slab" },
    ],
    productsData: [
      {
          color: 'Black',
          countryOfOrigin: 'India',
          colourName: 'Black Vein',
          colourCode: '1052',
          stoneForm: 'Slabs and Cut to size as per requirement',
          stoneFinish: 'Polished, Leather, Honed',
          size: '3050*1120 mm / Other custom sizes are possible',
          thickness: '15 mm, 20 mm, 30 mm'
      }
  ],
  basicParams: [
      {
          absorptionByWeight: '0.06%',
          appDensityKG: '>2100',
          abrasionResistance: '34',
          modulusOfRupture: '55 - 65',
          flexuralStrength: '40 - 60',
          compressStrength: '150 - 250',
          mohsHardness: '6.0 - 7.0',
          specularGloss: '55 - 70'
      }
  ]
  },
  //.4
  SummerStorm3031: {
    name: "3031 Summer Storm",
    images: [
        { src: "/images/ColourRange/Cobalto/CobaltoDetails/3031-detials-img.jpg", alt: "3031 Summer Storm" },
    ],
    Subimages: [
        { src: "/images/ColourRange/Cobalto/CobaltoDetails/3031-detials-sub-img-1.jpg", alt: "Full Slab", name: "Full Slab" },
        // { src: "/images/ColourRange/Cobalto/CobaltoDetails/", alt: "Full Slab", name: "Full Slab" },
    ],
    productsData: [
      {
          color: 'White',
          countryOfOrigin: 'India',
          colourName: 'Summer Storm',
          colourCode: '3031',
          stoneForm: 'Slabs and Cut to size as per requirement',
          stoneFinish: 'Polished, Leather, Honed',
          size: '3050*1120 mm / Other custom sizes are possible',
          thickness: '15 mm, 20 mm, 30 mm'
      }
  ],
  basicParams: [
      {
          absorptionByWeight: '0.06%',
          appDensityKG: '>2100',
          abrasionResistance: '34',
          modulusOfRupture: '55 - 65',
          flexuralStrength: '40 - 60',
          compressStrength: '150 - 250',
          mohsHardness: '6.0 - 7.0',
          specularGloss: '55 - 70'
      }
  ]
  
  },
  //.5
  SaharaDream3032: {
    name: "3032 Sahara Dream",
    images: [
        { src: "/images/ColourRange/Cobalto/CobaltoDetails/3032-details-img.jpg", alt: "3032 Sahara Dream" },
    ],
    Subimages: [
        { src: "/images/ColourRange/Cobalto/CobaltoDetails/3032-details-sub-img-1.jpg",alt: "Full Slab", name: "Full Slab"  },
        // { src: "/images/ColourRange/Cobalto/CobaltoDetails/", alt: "Full Slab", name: "Full Slab" },
    ],
    productsData: [
      {
          color: 'White',
          countryOfOrigin: 'India',
          colourName: 'Sahara Dream',
          colourCode: '3032',
          stoneForm: 'Slabs and Cut to size as per requirement',
          stoneFinish: 'Polished, Leather, Honed',
          size: '3050*1120 mm / Other custom sizes are possible',
          thickness: '15 mm, 20 mm, 30 mm'
      }
  ],
  basicParams: [
      {
          absorptionByWeight: '0.06%',
          appDensityKG: '>2100',
          abrasionResistance: '34',
          modulusOfRupture: '55 - 65',
          flexuralStrength: '40 - 60',
          compressStrength: '150 - 250',
          mohsHardness: '6.0 - 7.0',
          specularGloss: '55 - 70'
      }
  ]
  },
  //.6
  CloudyWhite3033: {
    name: "3033 Cloudy White",
    images: [
        { src: "/images/ColourRange/Cobalto/CobaltoDetails/3033-details-img.jpg", alt: "3033 Cloudy White" },
    ],
    Subimages: [
        // { src: "/images/ColourRange/Cobalto/CobaltoDetails/", alt: "Close-up", name: "Close-up" },
        { src: "/images/ColourRange/Cobalto/CobaltoDetails/3033-details-sub-img-1.jpg", alt: "Full Slab", name: "Full Slab" },
    ],
    productsData: [
      {
          color: 'White',
          countryOfOrigin: 'India',
          colourName: 'Cloudy White',
          colourCode: '3033',
          stoneForm: 'Slabs and Cut to size as per requirement',
          stoneFinish: 'Polished, Leather, Honed',
          size: '3050*1120 mm / Other custom sizes are possible',
          thickness: '15 mm, 20 mm, 30 mm'
      }
  ],
  basicParams: [
      {
          absorptionByWeight: '0.06%',
          appDensityKG: '>2100',
          abrasionResistance: '34',
          modulusOfRupture: '55 - 65',
          flexuralStrength: '40 - 60',
          compressStrength: '150 - 250',
          mohsHardness: '6.0 - 7.0',
          specularGloss: '55 - 70'
      }
  ]
  
  },
  //.7
  CremaVenice3034: {
    name: "3034 Crema Venice",
    images: [
        { src: "/images/ColourRange/Cobalto/CobaltoDetails/3034-details-img.jpg", alt: "3034 Crema Venice" },
    ],
    Subimages: [
        // { src: "/images/ColourRange/Cobalto/CobaltoDetails/", alt: "Close-up", name: "Close-up" },
        { src: "/images/ColourRange/Cobalto/CobaltoDetails/3034-details-sub-img-1.jpg", alt: "Full Slab", name: "Full Slab" },
    ],
    productsData: [
      {
          color: 'White',
          countryOfOrigin: 'India',
          colourName: 'Crema Venice',
          colourCode: '3034',
          stoneForm: 'Slabs and Cut to size as per requirement',
          stoneFinish: 'Polished, Leather, Honed',
          size: '3050*1120 mm / Other custom sizes are possible',
          thickness: '15 mm, 20 mm, 30 mm'
      }
  ],
  basicParams: [
      {
          absorptionByWeight: '0.06%',
          appDensityKG: '>2100',
          abrasionResistance: '34',
          modulusOfRupture: '55 - 65',
          flexuralStrength: '40 - 60',
          compressStrength: '150 - 250',
          mohsHardness: '6.0 - 7.0',
          specularGloss: '55 - 70'
      }
  ]
  
  },
  //.8
  Raindrops3035: {
    name: "3035 Raindrops",
    images: [
        { src: "/images/ColourRange/Cobalto/CobaltoDetails/3035-details-img.jpg", alt: "3035 Raindrops" },
    ],
    Subimages: [
        // { src: "/images/ColourRange/Cobalto/CobaltoDetails/", alt: "Close-up", name: "Close-up" },
        { src: "/images/ColourRange/Cobalto/CobaltoDetails/3035-details-sub-img-1.jpg", alt: "Full Slab", name: "Full Slab" },
    ],
    productsData: [
      {
          color: 'White',
          countryOfOrigin: 'India',
          colourName: 'Raindrops',
          colourCode: '3035',
          stoneForm: 'Slabs and Cut to size as per requirement',
          stoneFinish: 'Polished, Leather, Honed',
          size: '3050*1120 mm / Other custom sizes are possible',
          thickness: '15 mm, 20 mm, 30 mm'
      }
  ],
  basicParams: [
      {
          absorptionByWeight: '0.06%',
          appDensityKG: '>2100',
          abrasionResistance: '34',
          modulusOfRupture: '55 - 65',
          flexuralStrength: '40 - 60',
          compressStrength: '150 - 250',
          mohsHardness: '6.0 - 7.0',
          specularGloss: '55 - 70'
      }
  ]
  
  },
  //.9
  RomaGrigio6012: {
    name: "6012 Roma Grigio",
    images: [
        { src: "/images/ColourRange/Cobalto/CobaltoDetails/6012-details-img.jpg", alt: "6012 Roma Grigio" },
    ],
    Subimages: [
        { src: "/images/ColourRange/Cobalto/CobaltoDetails/6012-details-sub-img-1.jpg", alt: "Close-up", name: "Close-up" },
        { src: "/images/ColourRange/Cobalto/CobaltoDetails/6012-details-sub-img-2.jpg", alt: "Full Slab", name: "Full Slab" },
    ],
    productsData: [
      {
          color: 'Grey',
          countryOfOrigin: 'India',
          colourName: 'Roma Grigio',
          colourCode: '6012',
          stoneForm: 'Slabs and Cut to size as per requirement',
          stoneFinish: 'Polished, Leather, Honed',
          size: '3050*1120 mm / Other custom sizes are possible',
          thickness: '15 mm, 20 mm, 30 mm'
      }
  ],
  basicParams: [
      {
          absorptionByWeight: '0.06%',
          appDensityKG: '>2100',
          abrasionResistance: '34',
          modulusOfRupture: '55 - 65',
          flexuralStrength: '40 - 60',
          compressStrength: '150 - 250',
          mohsHardness: '6.0 - 7.0',
          specularGloss: '55 - 70'
      }
  ]
  
  },
  //.10
  BiancoPuro9018: {
    name: "9018 Bianco Puro",
    images: [
        { src: "/images/ColourRange/Cobalto/CobaltoDetails/9018-details-img.jpg", alt: "9018 Bianco Puro" },
    ],
    Subimages: [
        { src: "/images/ColourRange/Cobalto/CobaltoDetails/9018-details-sub-img-1.jpg", alt: "Close-up", name: "Close-up" },
        { src: "/images/ColourRange/Cobalto/CobaltoDetails/9018-details-sub-img-2.jpg", alt: "Full Slab", name: "Full Slab" },
        { src: "/images/ColourRange/Cobalto/CobaltoDetails/9018-details-sub-img-3.jpg", alt: "Application", name: "Application" },
    ],
    productsData: [
      {
          color: 'White',
          countryOfOrigin: 'India',
          colourName: 'Bianco Puro',
          colourCode: '9018',
          stoneForm: 'Slabs and Cut to size as per requirement',
          stoneFinish: 'Polished, Leather, Honed',
          size: '3050*1120 mm / Other custom sizes are possible',
          thickness: '15 mm, 20 mm, 30 mm'
      }
  ],
  basicParams: [
      {
          absorptionByWeight: '0.06%',
          appDensityKG: '>2100',
          abrasionResistance: '34',
          modulusOfRupture: '55 - 65',
          flexuralStrength: '40 - 60',
          compressStrength: '150 - 250',
          mohsHardness: '6.0 - 7.0',
          specularGloss: '55 - 70'
      }
  ]
  
  },
};

export default QuartzcolourRangeDetailsData;
