// CategoriesPage.js
import React from "react";
import { Link } from "react-router-dom";
import banner from "./images/Quartz-banner.png";

// categories.js

const categories = {
  ProductCategories: {
    name: "",
    description:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour",
    items: [
      {
        id: 1,
        name: "Laminated",
        thumbnail: "./images/Laminated.png",
      },
      {
        id: 2,
        name: "Mitered",
        thumbnail: "./images/Mitered.png",
      },
      {
        id: 3,
        name: "Standard",
        thumbnail: "./images/Standard.png",
      },
    ],
  },
};

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const EdgeProfiles = () => (
  <div>
    <div className="Banner">
      <img src={banner} alt="" />
      <h1>Edge Profiles</h1>
    </div>
    <div className="product-sec-1-wrap">
      <div className="wrapper">
        <p>
          It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout. The point of
          using Lorem Ipsum is that it has a more-or-less normal distribution of
          letters, as opposed to using 'Content here, content here', making it
          look like readable English. Many desktop publishing packages and web
          page editors now use Lorem Ipsum as their default model text, and a
          search for 'lorem ipsum'
        </p>
      </div>
    </div>

    <div className="product-list-wrap-2">
      {Object.keys(categories).map((category) => (
        <div key={category} className="product-list-bg">
          <div className="wrapper">
            {/* <h2>{category}</h2>  */}
            {/* <p>{categories[category].description}</p>{" "} */}
            {/* Display category description */}
            <div className="category-images">
              {categories[category].items.map((item) => (
                <Link key={item.id} to={`/EdgeProfiles/${category}/${item.id}`}>
                  <img src={item.thumbnail} alt={item.name} />
                  <div className="catname">{item.name}</div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default EdgeProfiles;
